@charset "UTF-8";
/*----------------------------------------------------------------------
  * Onekit - Bootstrap Landing Page Template
  * Copyright MadeThemes
  * Licensed under  (https://support.wrapbootstrap.com/help/usage-licenses)
------------------------------------------------------------------------*/
:root,
[data-bs-theme=light] {
  --bs-blue: #3928d3;
  --bs-indigo: #4f46e5;
  --bs-purple: #5b2be0;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffcc43;
  --bs-green: #187958;
  --bs-teal: #22D3EE;
  --bs-cyan: #0dcaf0;
  --bs-black: #000000;
  --bs-white: #fff;
  --bs-gray: #4B5563;
  --bs-gray-dark: #1F2937;
  --bs-gray-100: #f9fafb;
  --bs-gray-200: #E5E7EB;
  --bs-gray-300: #D1D5DB;
  --bs-gray-400: #9CA3AF;
  --bs-gray-500: #6B7280;
  --bs-gray-600: #4B5563;
  --bs-gray-700: #374151;
  --bs-gray-800: #1F2937;
  --bs-gray-900: #111827;
  --bs-primary: #5b2be0;
  --bs-secondary: #1d253d;
  --bs-success: #187958;
  --bs-info: #22D3EE;
  --bs-warning: #ffcc43;
  --bs-danger: #dc3545;
  --bs-light: #f9fafb;
  --bs-dark: #111827;
  --bs-white: #fff;
  --bs-black: #000000;
  --bs-primary-rgb: 91, 43, 224;
  --bs-secondary-rgb: 29, 37, 61;
  --bs-success-rgb: 24, 121, 88;
  --bs-info-rgb: 34, 211, 238;
  --bs-warning-rgb: 255, 204, 67;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 249, 250, 251;
  --bs-dark-rgb: 17, 24, 39;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-primary-text: #2e20a9;
  --bs-secondary-text: #4B5563;
  --bs-success-text: #136146;
  --bs-info-text: #087990;
  --bs-warning-text: #997a28;
  --bs-danger-text: #b02a37;
  --bs-light-text: #4B5563;
  --bs-dark-text: #374151;
  --bs-primary-bg-subtle: #d7d4f6;
  --bs-secondary-bg-subtle: #f9fafb;
  --bs-success-bg-subtle: #d1e4de;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff5d9;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfdfd;
  --bs-dark-bg-subtle: #9CA3AF;
  --bs-primary-border-subtle: #b0a9ed;
  --bs-secondary-border-subtle: #E5E7EB;
  --bs-success-border-subtle: #a3c9bc;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffebb4;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #E5E7EB;
  --bs-dark-border-subtle: #6B7280;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 75, 85, 99;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.3;
  --bs-body-color: #4B5563;
  --bs-emphasis-color: #000000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(75, 85, 99, 0.75);
  --bs-secondary-color-rgb: 75, 85, 99;
  --bs-secondary-bg: #E5E7EB;
  --bs-secondary-bg-rgb: 229, 231, 235;
  --bs-tertiary-color: rgba(75, 85, 99, 0.5);
  --bs-tertiary-color-rgb: 75, 85, 99;
  --bs-tertiary-bg: #f9fafb;
  --bs-tertiary-bg-rgb: 249, 250, 251;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-link-color: #5b2be0;
  --bs-link-color-rgb: 91, 43, 224;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #4922b3;
  --bs-link-hover-color-rgb: 73, 34, 179;
  --bs-link-hover-decoration: none;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff5d9;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #E5E7EB;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.275rem;
  --bs-border-radius-lg: 0.75rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 3.25rem;
  --bs-box-shadow: 0 0.3rem 1.8rem 0 rgba(55, 26, 134, 0.2);
  --bs-box-shadow-sm: 0 0.2rem 1.25rem 0 rgba(55, 26, 134, 0.1);
  --bs-box-shadow-lg: 0 0.4rem 2.2rem 0 rgba(55, 26, 134, 0.3);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(55, 26, 134, 0.075);
  --bs-emphasis-color: #000000;
  --bs-form-control-bg: var(--bs-body-bg);
  --bs-form-control-disabled-bg: var(--bs-secondary-bg);
  --bs-highlight-bg: #fff5d9;
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
  --bs-black-white-rgb: 0, 0, 0;
  --bs-black-white: #000000;
  --bs-light-dark-rgb: 249, 250, 251;
  --bs-light-dark: #f9fafb;
  --bs-white-black-rgb: 255, 255, 255;
  --bs-white-black: #fff;
  --bs-border-light-dark: #E5E7EB;
  --bs-text-dark-light: #374151;
}

[data-bs-theme=dark] {
  --bs-body-color: #6B7280;
  --bs-body-color-rgb: 107, 114, 128;
  --bs-body-bg: #000000;
  --bs-body-bg-rgb: 0, 0, 0;
  --bs-emphasis-color: #f9fafb;
  --bs-emphasis-color-rgb: 249, 250, 251;
  --bs-secondary-color: rgba(107, 114, 128, 0.75);
  --bs-secondary-color-rgb: 107, 114, 128;
  --bs-secondary-bg: #1F2937;
  --bs-secondary-bg-rgb: 31, 41, 55;
  --bs-tertiary-color: rgba(107, 114, 128, 0.5);
  --bs-tertiary-color-rgb: 107, 114, 128;
  --bs-tertiary-bg: #18212f;
  --bs-tertiary-bg-rgb: 24, 33, 47;
  --bs-emphasis-color: #fff;
  --bs-primary-text: #887ee5;
  --bs-secondary-text: #D1D5DB;
  --bs-success-text: #74af9b;
  --bs-info-text: #6edff6;
  --bs-warning-text: #ffe08e;
  --bs-danger-text: #ea868f;
  --bs-light-text: #f9fafb;
  --bs-dark-text: #D1D5DB;
  --bs-primary-bg-subtle: #0b082a;
  --bs-secondary-bg-subtle: #111827;
  --bs-success-bg-subtle: #051812;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #33290d;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #1F2937;
  --bs-dark-bg-subtle: #10151c;
  --bs-primary-border-subtle: #22187f;
  --bs-secondary-border-subtle: #374151;
  --bs-success-border-subtle: #0e4935;
  --bs-info-border-subtle: #055160;
  --bs-warning-border-subtle: #66521b;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #374151;
  --bs-dark-border-subtle: #1F2937;
  --bs-heading-color: #fff;
  --bs-link-color: #887ee5;
  --bs-link-hover-color: #b0a9ed;
  --bs-link-color-rgb: 136, 126, 229;
  --bs-link-hover-color-rgb: 176, 169, 237;
  --bs-code-color: #e685b5;
  --bs-border-color: #374151;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-primary-rgb: 124, 85, 230;
  --bs-secondary-rgb: 23, 30, 49;
  --bs-black-white-rgb: 255, 255, 255;
  --bs-black-white: #fff;
  --bs-light-dark-rgb: 17, 24, 39;
  --bs-light-dark: #111827;
  --bs-white-black-rgb: 0, 0, 0;
  --bs-white-black: #000000;
  --bs-border-light-dark: #374151;
  --bs-text-dark-light: #E5E7EB;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: #E5E7EB;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.3;
  color: var(--bs-heading-color, inherit);
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}
h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}

@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}
h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}

@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}
h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}
h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.8em;
}

mark, .mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}

a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
  text-decoration: none;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.8em;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.8em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.8em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.275rem;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6B7280;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.5625rem + 3.75vw);
  font-weight: 300;
  line-height: 1.3;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 4.375rem;
  }
}
.display-2 {
  font-size: calc(1.50625rem + 3.075vw);
  font-weight: 300;
  line-height: 1.3;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 3.8125rem;
  }
}
.display-3 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.3;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 3rem;
  }
}
.display-4 {
  font-size: calc(1.40625rem + 1.875vw);
  font-weight: 300;
  line-height: 1.3;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 2.8125rem;
  }
}
.display-5 {
  font-size: calc(1.39375rem + 1.725vw);
  font-weight: 300;
  line-height: 1.3;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 2.6875rem;
  }
}
.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.3;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.8em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.8em;
  color: #4B5563;
}

.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid #E5E7EB;
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875rem;
  color: var(--bs-secondary-color);
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1200px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 4.5rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 4.5rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 6rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 6rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 9rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 9rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 12rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 12rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 4.5rem;
  }
  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 4.5rem;
  }
  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 6rem;
  }
  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 6rem;
  }
  .g-sm-8,
  .gx-sm-8 {
    --bs-gutter-x: 9rem;
  }
  .g-sm-8,
  .gy-sm-8 {
    --bs-gutter-y: 9rem;
  }
  .g-sm-9,
  .gx-sm-9 {
    --bs-gutter-x: 12rem;
  }
  .g-sm-9,
  .gy-sm-9 {
    --bs-gutter-y: 12rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 4.5rem;
  }
  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 4.5rem;
  }
  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 6rem;
  }
  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 6rem;
  }
  .g-md-8,
  .gx-md-8 {
    --bs-gutter-x: 9rem;
  }
  .g-md-8,
  .gy-md-8 {
    --bs-gutter-y: 9rem;
  }
  .g-md-9,
  .gx-md-9 {
    --bs-gutter-x: 12rem;
  }
  .g-md-9,
  .gy-md-9 {
    --bs-gutter-y: 12rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 4.5rem;
  }
  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 4.5rem;
  }
  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 6rem;
  }
  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 6rem;
  }
  .g-lg-8,
  .gx-lg-8 {
    --bs-gutter-x: 9rem;
  }
  .g-lg-8,
  .gy-lg-8 {
    --bs-gutter-y: 9rem;
  }
  .g-lg-9,
  .gx-lg-9 {
    --bs-gutter-x: 12rem;
  }
  .g-lg-9,
  .gy-lg-9 {
    --bs-gutter-y: 12rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 4.5rem;
  }
  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 4.5rem;
  }
  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 6rem;
  }
  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 6rem;
  }
  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 9rem;
  }
  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 9rem;
  }
  .g-xl-9,
  .gx-xl-9 {
    --bs-gutter-x: 12rem;
  }
  .g-xl-9,
  .gy-xl-9 {
    --bs-gutter-y: 12rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 4.5rem;
  }
  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 4.5rem;
  }
  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 6rem;
  }
  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 6rem;
  }
  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 9rem;
  }
  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 9rem;
  }
  .g-xxl-9,
  .gx-xxl-9 {
    --bs-gutter-x: 12rem;
  }
  .g-xxl-9,
  .gy-xxl-9 {
    --bs-gutter-y: 12rem;
  }
}
.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}

.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-color: #000000;
  --bs-table-bg: #ded5f9;
  --bs-table-border-color: #c8c0e0;
  --bs-table-striped-bg: #d3caed;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #c8c0e0;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #cdc5e6;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000000;
  --bs-table-bg: #d2d3d8;
  --bs-table-border-color: #bdbec2;
  --bs-table-striped-bg: #c8c8cd;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #bdbec2;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #c2c3c8;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000000;
  --bs-table-bg: #d1e4de;
  --bs-table-border-color: #bccdc8;
  --bs-table-striped-bg: #c7d9d3;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #bccdc8;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #c1d3cd;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000000;
  --bs-table-bg: #d3f6fc;
  --bs-table-border-color: #bedde3;
  --bs-table-striped-bg: #c8eaef;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #bedde3;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #c3e4e9;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000000;
  --bs-table-bg: #fff5d9;
  --bs-table-border-color: #e6ddc3;
  --bs-table-striped-bg: #f2e9ce;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #e6ddc3;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #ece3c9;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #dfc2c4;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000000;
  --bs-table-bg: #f9fafb;
  --bs-table-border-color: #e0e1e2;
  --bs-table-striped-bg: #edeeee;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #e0e1e2;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #e6e7e8;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #111827;
  --bs-table-border-color: #292f3d;
  --bs-table-striped-bg: #1d2432;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #292f3d;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #232937;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.75rem + var(--bs-border-width));
  padding-bottom: calc(0.75rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.3;
}

.col-form-label-lg {
  padding-top: calc(1rem + var(--bs-border-width));
  padding-bottom: calc(1rem + var(--bs-border-width));
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.8em;
  color: #6B7280;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.75rem 1.875rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3;
  color: #374151;
  background-color: var(--bs-form-control-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid #E5E7EB;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}

.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: #374151;
  background-color: var(--bs-form-control-bg);
  border-color: #7c55e6;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(91, 43, 224, 0.25);
}

.form-control::-webkit-date-and-time-value {
  height: 1.3em;
}

.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}

.form-control::placeholder {
  color: #6B7280;
  opacity: 1;
}

.form-control:disabled {
  background-color: var(--bs-form-control-disabled-bg);
  opacity: 1;
}

.form-control::file-selector-button {
  padding: 0.75rem 1.875rem;
  margin: -0.75rem -1.875rem;
  margin-inline-end: 1.875rem;
  color: #374151;
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.75rem 0;
  margin-bottom: 0;
  line-height: 1.3;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0;
}

.form-control-plaintext:focus {
  outline: 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.3em + 1rem + var(--bs-border-width) * 2);
  padding: 0.5rem 1.25rem;
  font-size: 0.875rem;
  border-radius: 0.275rem;
}

.form-control-sm::file-selector-button {
  padding: 0.5rem 1.25rem;
  margin: -0.5rem -1.25rem;
  margin-inline-end: 1.25rem;
}

.form-control-lg {
  min-height: calc(1.3em + 2rem + var(--bs-border-width) * 2);
  padding: 1rem 2.5rem;
  font-size: 1.25rem;
  border-radius: 0.75rem;
}

.form-control-lg::file-selector-button {
  padding: 1rem 2.5rem;
  margin: -1rem -2.5rem;
  margin-inline-end: 2.5rem;
}

textarea.form-control {
  min-height: calc(1.3em + 1.5rem + var(--bs-border-width) * 2);
}

textarea.form-control-sm {
  min-height: calc(1.3em + 1rem + var(--bs-border-width) * 2);
}

textarea.form-control-lg {
  min-height: calc(1.3em + 2rem + var(--bs-border-width) * 2);
}

.form-control-color {
  width: 3rem;
  height: calc(1.3em + 1.5rem + var(--bs-border-width) * 2);
  padding: 0.75rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 0.375rem;
}

.form-control-color::-webkit-color-swatch {
  border-radius: 0.375rem;
}

.form-control-color.form-control-sm {
  height: calc(1.3em + 1rem + var(--bs-border-width) * 2);
}

.form-control-color.form-control-lg {
  height: calc(1.3em + 2rem + var(--bs-border-width) * 2);
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%231F2937' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.75rem 5.625rem 0.75rem 1.875rem;
  -moz-padding-start: calc(1.875rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3;
  color: #374151;
  background-color: var(--bs-form-control-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 1.875rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid #E5E7EB;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #7c55e6;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(91, 43, 224, 0.25);
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 1.875rem;
  background-image: none;
}

.form-select:disabled {
  background-color: var(--bs-form-control-disabled-bg);
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #374151;
}

.form-select-sm {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.25rem;
  font-size: 0.875rem;
  border-radius: 0.275rem;
}

.form-select-lg {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2.5rem;
  font-size: 1.25rem;
  border-radius: 0.75rem;
}

[data-bs-theme=dark] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%236B7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.3rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}

.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-form-control-bg);
  width: 1em;
  height: 1em;
  margin-top: 0.15em;
  vertical-align: top;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  appearance: none;
  print-color-adjust: exact;
}

.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}

.form-check-input[type=radio] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #7c55e6;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(91, 43, 224, 0.25);
}

.form-check-input:checked {
  background-color: #5b2be0;
  border-color: #5b2be0;
}

.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type=checkbox]:indeterminate {
  background-color: #5b2be0;
  border-color: #5b2be0;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%237c55e6'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}

.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0 rgba(91, 43, 224, 0.25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0 rgba(91, 43, 224, 0.25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #5b2be0;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #cebff6;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #5b2be0;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #cebff6;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}

.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}

.form-floating::before:not(.form-control:disabled) {
  position: absolute;
  top: var(--bs-border-width);
  left: var(--bs-border-width);
  width: calc(100% - (0.325em + 0.375rem + 0.65em + 0.75rem));
  height: 1.875em;
  content: "";
  background-color: var(--bs-form-control-bg);
  border-radius: 0.375rem;
}

.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + var(--bs-border-width) * 2);
  line-height: 1.25;
}

.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem 1.875rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 1.875rem;
}

.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control-plaintext ~ label {
  border-width: var(--bs-border-width) 0;
}

.form-floating > .form-control:disabled ~ label {
  color: #4B5563;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}

.input-group .btn {
  position: relative;
  z-index: 2;
}

.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.75rem 1.875rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3;
  color: #374151;
  text-align: center;
  white-space: nowrap;
  background-color: transparent;
  border: var(--bs-border-width) solid #E5E7EB;
  border-radius: 0.375rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 1rem 2.5rem;
  font-size: 1.25rem;
  border-radius: 0.75rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.5rem 1.25rem;
  font-size: 0.875rem;
  border-radius: 0.275rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 7.5rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.8em;
  color: var(--bs-success-text);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-success);
  padding-right: calc(1.3em + 1.5rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23187958' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.325em + 0.375rem) center;
  background-size: calc(0.65em + 0.75rem) calc(0.65em + 0.75rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--bs-success);
  box-shadow: 0 0 0 0 rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.3em + 1.5rem);
  background-position: top calc(0.325em + 0.375rem) right calc(0.325em + 0.375rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-success);
}

.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23187958' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 10.3125rem;
  background-position: right 1.875rem center, center right 5.625rem;
  background-size: 16px 12px, calc(0.65em + 0.75rem) calc(0.65em + 0.75rem);
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-success);
  box-shadow: 0 0 0 0 rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + 1.3em + 1.5rem);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-success);
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-success-text);
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0 rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-success-text);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.8em;
  color: var(--bs-danger-text);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-danger);
  padding-right: calc(1.3em + 1.5rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.325em + 0.375rem) center;
  background-size: calc(0.65em + 0.75rem) calc(0.65em + 0.75rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--bs-danger);
  box-shadow: 0 0 0 0 rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.3em + 1.5rem);
  background-position: top calc(0.325em + 0.375rem) right calc(0.325em + 0.375rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-danger);
}

.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  padding-right: 10.3125rem;
  background-position: right 1.875rem center, center right 5.625rem;
  background-size: 16px 12px, calc(0.65em + 0.75rem) calc(0.65em + 0.75rem);
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--bs-danger);
  box-shadow: 0 0 0 0 rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + 1.3em + 1.5rem);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-danger);
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--bs-danger-text);
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0 rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-danger-text);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 1.875rem;
  --bs-btn-padding-y: 0.75rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.3;
  --bs-btn-color: #4B5563;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0 rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}

.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #5b2be0;
  --bs-btn-border-color: #5b2be0;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #4d25be;
  --bs-btn-hover-border-color: #4922b3;
  --bs-btn-focus-shadow-rgb: 116, 75, 229;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4922b3;
  --bs-btn-active-border-color: #4420a8;
  --bs-btn-active-shadow: inherit;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #5b2be0;
  --bs-btn-disabled-border-color: #5b2be0;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #1d253d;
  --bs-btn-border-color: #1d253d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #191f34;
  --bs-btn-hover-border-color: #171e31;
  --bs-btn-focus-shadow-rgb: 63, 70, 90;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #171e31;
  --bs-btn-active-border-color: #161c2e;
  --bs-btn-active-shadow: inherit;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #1d253d;
  --bs-btn-disabled-border-color: #1d253d;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #187958;
  --bs-btn-border-color: #187958;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #14674b;
  --bs-btn-hover-border-color: #136146;
  --bs-btn-focus-shadow-rgb: 59, 141, 113;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #136146;
  --bs-btn-active-border-color: #125b42;
  --bs-btn-active-shadow: inherit;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #187958;
  --bs-btn-disabled-border-color: #187958;
}

.btn-info {
  --bs-btn-color: #000000;
  --bs-btn-bg: #22D3EE;
  --bs-btn-border-color: #22D3EE;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #43daf1;
  --bs-btn-hover-border-color: #38d7f0;
  --bs-btn-focus-shadow-rgb: 29, 179, 202;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #4edcf1;
  --bs-btn-active-border-color: #38d7f0;
  --bs-btn-active-shadow: inherit;
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #22D3EE;
  --bs-btn-disabled-border-color: #22D3EE;
}

.btn-warning {
  --bs-btn-color: #000000;
  --bs-btn-bg: #ffcc43;
  --bs-btn-border-color: #ffcc43;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ffd45f;
  --bs-btn-hover-border-color: #ffd156;
  --bs-btn-focus-shadow-rgb: 217, 173, 57;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ffd669;
  --bs-btn-active-border-color: #ffd156;
  --bs-btn-active-shadow: inherit;
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #ffcc43;
  --bs-btn-disabled-border-color: #ffcc43;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inherit;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-light {
  --bs-btn-color: #000000;
  --bs-btn-bg: #f9fafb;
  --bs-btn-border-color: #f9fafb;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #d4d5d5;
  --bs-btn-hover-border-color: #c7c8c9;
  --bs-btn-focus-shadow-rgb: 212, 213, 213;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #c7c8c9;
  --bs-btn-active-border-color: #bbbcbc;
  --bs-btn-active-shadow: inherit;
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #f9fafb;
  --bs-btn-disabled-border-color: #f9fafb;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #111827;
  --bs-btn-border-color: #111827;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #353b47;
  --bs-btn-hover-border-color: #292f3d;
  --bs-btn-focus-shadow-rgb: 53, 59, 71;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #414652;
  --bs-btn-active-border-color: #292f3d;
  --bs-btn-active-shadow: inherit;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #111827;
  --bs-btn-disabled-border-color: #111827;
}

.btn-white {
  --bs-btn-color: #000000;
  --bs-btn-bg: #fff;
  --bs-btn-border-color: #fff;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: white;
  --bs-btn-hover-border-color: white;
  --bs-btn-focus-shadow-rgb: 217, 217, 217;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: white;
  --bs-btn-active-border-color: white;
  --bs-btn-active-shadow: inherit;
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #fff;
  --bs-btn-disabled-border-color: #fff;
}

.btn-black {
  --bs-btn-color: #fff;
  --bs-btn-bg: #000000;
  --bs-btn-border-color: #000000;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: black;
  --bs-btn-hover-border-color: black;
  --bs-btn-focus-shadow-rgb: 38, 38, 38;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: black;
  --bs-btn-active-border-color: black;
  --bs-btn-active-shadow: inherit;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #000000;
  --bs-btn-disabled-border-color: #000000;
}

.btn-outline-primary {
  --bs-btn-color: #5b2be0;
  --bs-btn-border-color: #5b2be0;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5b2be0;
  --bs-btn-hover-border-color: #5b2be0;
  --bs-btn-focus-shadow-rgb: 91, 43, 224;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #5b2be0;
  --bs-btn-active-border-color: #5b2be0;
  --bs-btn-active-shadow: inherit;
  --bs-btn-disabled-color: #5b2be0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #5b2be0;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #1d253d;
  --bs-btn-border-color: #1d253d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #1d253d;
  --bs-btn-hover-border-color: #1d253d;
  --bs-btn-focus-shadow-rgb: 29, 37, 61;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #1d253d;
  --bs-btn-active-border-color: #1d253d;
  --bs-btn-active-shadow: inherit;
  --bs-btn-disabled-color: #1d253d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #1d253d;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #187958;
  --bs-btn-border-color: #187958;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #187958;
  --bs-btn-hover-border-color: #187958;
  --bs-btn-focus-shadow-rgb: 24, 121, 88;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #187958;
  --bs-btn-active-border-color: #187958;
  --bs-btn-active-shadow: inherit;
  --bs-btn-disabled-color: #187958;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #187958;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #22D3EE;
  --bs-btn-border-color: #22D3EE;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #22D3EE;
  --bs-btn-hover-border-color: #22D3EE;
  --bs-btn-focus-shadow-rgb: 34, 211, 238;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #22D3EE;
  --bs-btn-active-border-color: #22D3EE;
  --bs-btn-active-shadow: inherit;
  --bs-btn-disabled-color: #22D3EE;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #22D3EE;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffcc43;
  --bs-btn-border-color: #ffcc43;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ffcc43;
  --bs-btn-hover-border-color: #ffcc43;
  --bs-btn-focus-shadow-rgb: 255, 204, 67;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ffcc43;
  --bs-btn-active-border-color: #ffcc43;
  --bs-btn-active-shadow: inherit;
  --bs-btn-disabled-color: #ffcc43;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffcc43;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inherit;
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f9fafb;
  --bs-btn-border-color: #f9fafb;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #f9fafb;
  --bs-btn-hover-border-color: #f9fafb;
  --bs-btn-focus-shadow-rgb: 249, 250, 251;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #f9fafb;
  --bs-btn-active-border-color: #f9fafb;
  --bs-btn-active-shadow: inherit;
  --bs-btn-disabled-color: #f9fafb;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f9fafb;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #111827;
  --bs-btn-border-color: #111827;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #111827;
  --bs-btn-hover-border-color: #111827;
  --bs-btn-focus-shadow-rgb: 17, 24, 39;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #111827;
  --bs-btn-active-border-color: #111827;
  --bs-btn-active-shadow: inherit;
  --bs-btn-disabled-color: #111827;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #111827;
  --bs-gradient: none;
}

.btn-outline-white {
  --bs-btn-color: #fff;
  --bs-btn-border-color: #fff;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-border-color: #fff;
  --bs-btn-focus-shadow-rgb: 255, 255, 255;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #fff;
  --bs-btn-active-border-color: #fff;
  --bs-btn-active-shadow: inherit;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fff;
  --bs-gradient: none;
}

.btn-outline-black {
  --bs-btn-color: #000000;
  --bs-btn-border-color: #000000;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #000000;
  --bs-btn-hover-border-color: #000000;
  --bs-btn-focus-shadow-rgb: 0, 0, 0;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #000000;
  --bs-btn-active-border-color: #000000;
  --bs-btn-active-shadow: inherit;
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #000000;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #4B5563;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 116, 75, 229;
  text-decoration: underline;
}

.btn-link:hover, .btn-link:focus-visible {
  text-decoration: none;
}

.btn-link:focus-visible {
  color: var(--bs-btn-color);
}

.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 1rem;
  --bs-btn-padding-x: 2.5rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: 0.75rem;
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1.25rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: 0.275rem;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}
.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 12.5rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: #E5E7EB;
  --bs-dropdown-border-radius: 0.375rem;
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(0.375rem - var(--bs-border-width));
  --bs-dropdown-divider-bg: #E5E7EB;
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.4rem 2.2rem 0 #ded5f9;
  --bs-dropdown-link-color: #374151;
  --bs-dropdown-link-hover-color: #5b2be0;
  --bs-dropdown-link-hover-bg: transparent;
  --bs-dropdown-link-active-color: #5b2be0;
  --bs-dropdown-link-active-bg: transparent;
  --bs-dropdown-link-disabled-color: #6B7280;
  --bs-dropdown-item-padding-x: 1.5rem;
  --bs-dropdown-item-padding-y: 0.5rem;
  --bs-dropdown-header-color: #4B5563;
  --bs-dropdown-header-padding-x: 1.5rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropstart .dropdown-toggle::after {
  display: none;
}

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}

.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #D1D5DB;
  --bs-dropdown-bg: rgba(17, 24, 39, 0.85);
  --bs-dropdown-border-color: #E5E7EB;
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #D1D5DB;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: #E5E7EB;
  --bs-dropdown-link-hover-bg: transparent;
  --bs-dropdown-link-active-color: #5b2be0;
  --bs-dropdown-link-active-bg: transparent;
  --bs-dropdown-link-disabled-color: #6B7280;
  --bs-dropdown-header-color: #6B7280;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: 0.375rem;
}

.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--bs-border-width) * -1);
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 1.40625rem;
  padding-left: 1.40625rem;
}

.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.875rem;
  padding-left: 1.875rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(var(--bs-border-width) * -1);
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1.5rem;
  --bs-nav-link-padding-y: 0.75rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}

.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: #E5E7EB;
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) #E5E7EB;
  --bs-nav-tabs-link-active-color: #111827;
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  background: none;
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}

.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: 0.375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #5b2be0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
}

.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0;
  --bs-navbar-color: #374151;
  --bs-navbar-hover-color: #5b2be0;
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: #5b2be0;
  --bs-navbar-brand-padding-y: 0.5875rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: #5b2be0;
  --bs-navbar-brand-hover-color: #5b2be0;
  --bs-navbar-nav-link-padding-x: 1.2rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.25rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2875, 85, 99, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: transparent;
  --bs-navbar-toggler-border-radius: 0.375rem;
  --bs-navbar-toggler-focus-width: 0;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}

.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.75rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .show > .nav-link,
.navbar-nav .nav-link.active {
  color: var(--bs-navbar-active-color);
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: var(--bs-navbar-color);
}

.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}

.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}

.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark {
  --bs-navbar-color: #ded5f9;
  --bs-navbar-hover-color: #fff;
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: transparent;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%23ded5f9' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%23ded5f9' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1.25rem;
  --bs-card-spacer-x: 1.25rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: #E5E7EB;
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.625rem;
  --bs-card-cap-padding-x: 1.25rem;
  --bs-card-cap-bg: #f9fafb;
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}

.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}
.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: #E5E7EB;
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%234B5563'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%232e20a9'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #E5E7EB;
  --bs-accordion-btn-focus-box-shadow: inherit;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text);
  --bs-accordion-active-bg: #fff;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

[data-bs-theme=dark] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23887ee5'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23887ee5'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/");
}

.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: #4B5563;
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: #E5E7EB;
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: #fff;
  --bs-pagination-hover-bg: #5b2be0;
  --bs-pagination-hover-border-color: #5b2be0;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0 rgba(91, 43, 224, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #5b2be0;
  --bs-pagination-active-border-color: #5b2be0;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}

.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}

.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}

.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1);
}

.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}

.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: 0.75rem;
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: 0.275rem;
}

.badge {
  --bs-badge-padding-x: 0.75em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 0.375rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1.25rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 0 solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 0.375rem;
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3.75rem;
}

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1.25rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text);
}

.alert-white {
  --bs-alert-color: var(--bs-white-text);
  --bs-alert-bg: var(--bs-white-bg-subtle);
  --bs-alert-border-color: var(--bs-white-border-subtle);
  --bs-alert-link-color: var(--bs-white-text);
}

.alert-black {
  --bs-alert-color: var(--bs-black-text);
  --bs-alert-bg: var(--bs-black-bg-subtle);
  --bs-alert-border-color: var(--bs-black-border-subtle);
  --bs-alert-link-color: var(--bs-black-text);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: #D1D5DB;
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #5b2be0;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}
.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}
.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: #E5E7EB;
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 1.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: #f9fafb;
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #5b2be0;
  --bs-list-group-active-border-color: #5b2be0;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}

.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}

.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}

.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
}

.list-group-item-primary.list-group-item-action:active {
  --bs-list-group-active-color: var(--bs-emphasis-color);
  --bs-list-group-active-bg: var(--bs-primary-text);
  --bs-list-group-active-border-color: var(--bs-primary-text);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
}

.list-group-item-secondary.list-group-item-action:active {
  --bs-list-group-active-color: var(--bs-emphasis-color);
  --bs-list-group-active-bg: var(--bs-secondary-text);
  --bs-list-group-active-border-color: var(--bs-secondary-text);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
}

.list-group-item-success.list-group-item-action:active {
  --bs-list-group-active-color: var(--bs-emphasis-color);
  --bs-list-group-active-bg: var(--bs-success-text);
  --bs-list-group-active-border-color: var(--bs-success-text);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
}

.list-group-item-info.list-group-item-action:active {
  --bs-list-group-active-color: var(--bs-emphasis-color);
  --bs-list-group-active-bg: var(--bs-info-text);
  --bs-list-group-active-border-color: var(--bs-info-text);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
}

.list-group-item-warning.list-group-item-action:active {
  --bs-list-group-active-color: var(--bs-emphasis-color);
  --bs-list-group-active-bg: var(--bs-warning-text);
  --bs-list-group-active-border-color: var(--bs-warning-text);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
}

.list-group-item-danger.list-group-item-action:active {
  --bs-list-group-active-color: var(--bs-emphasis-color);
  --bs-list-group-active-bg: var(--bs-danger-text);
  --bs-list-group-active-border-color: var(--bs-danger-text);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
}

.list-group-item-light.list-group-item-action:active {
  --bs-list-group-active-color: var(--bs-emphasis-color);
  --bs-list-group-active-bg: var(--bs-light-text);
  --bs-list-group-active-border-color: var(--bs-light-text);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
}

.list-group-item-dark.list-group-item-action:active {
  --bs-list-group-active-color: var(--bs-emphasis-color);
  --bs-list-group-active-bg: var(--bs-dark-text);
  --bs-list-group-active-border-color: var(--bs-dark-text);
}

.list-group-item-white {
  --bs-list-group-color: var(--bs-white-text);
  --bs-list-group-bg: var(--bs-white-bg-subtle);
  --bs-list-group-border-color: var(--bs-white-border-subtle);
}

.list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-white-border-subtle);
}

.list-group-item-white.list-group-item-action:active {
  --bs-list-group-active-color: var(--bs-emphasis-color);
  --bs-list-group-active-bg: var(--bs-white-text);
  --bs-list-group-active-border-color: var(--bs-white-text);
}

.list-group-item-black {
  --bs-list-group-color: var(--bs-black-text);
  --bs-list-group-bg: var(--bs-black-bg-subtle);
  --bs-list-group-border-color: var(--bs-black-border-subtle);
}

.list-group-item-black.list-group-item-action:hover, .list-group-item-black.list-group-item-action:focus {
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-black-border-subtle);
}

.list-group-item-black.list-group-item-action:active {
  --bs-list-group-active-color: var(--bs-emphasis-color);
  --bs-list-group-active-bg: var(--bs-black-text);
  --bs-list-group-active-border-color: var(--bs-black-text);
}

.btn-close {
  --bs-btn-close-color: #000000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0 rgba(91, 43, 224, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity);
}

.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}

.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}

.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme=dark] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}

.toast.showing {
  opacity: 0;
}

.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}

.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}

.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 31.25rem;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: #E5E7EB;
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: 0 0.2rem 1.25rem 0 rgba(55, 26, 134, 0.1);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.3;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #111827;
  --bs-backdrop-opacity: 0.1;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}

.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}

.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.3rem 1.8rem 0 rgba(55, 26, 134, 0.2);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 21.875rem;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 50rem;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}

.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}

.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}

.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}

.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: #E5E7EB;
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: 0 0.3rem 1.8rem 0 rgba(55, 26, 134, 0.2);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: ;
  --bs-popover-header-bg: #f9fafb;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}

.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}

.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}

.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}

.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}

.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}

.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}

.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}

.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}

.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}

.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}

.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}

.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}
.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}
.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000000;
}

.carousel-dark .carousel-caption {
  color: #000000;
}

[data-bs-theme=dark] .carousel .carousel-control-prev-icon,
[data-bs-theme=dark] .carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target] {
  background-color: #000000;
}

[data-bs-theme=dark] .carousel .carousel-caption {
  color: #000000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: #E5E7EB;
  --bs-offcanvas-box-shadow: 0 0.2rem 1.25rem 0 rgba(55, 26, 134, 0.1);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.3;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}
.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}

.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}

.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}

.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}

.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}

.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #111827;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: 0.1;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}

.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}

.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000000 55%, rgba(0, 0, 0, 0.8) 75%, #000000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(91, 43, 224, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(29, 37, 61, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(24, 121, 88, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000000 !important;
  background-color: RGBA(34, 211, 238, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000000 !important;
  background-color: RGBA(255, 204, 67, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000000 !important;
  background-color: RGBA(249, 250, 251, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(17, 24, 39, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-white {
  color: #000000 !important;
  background-color: RGBA(255, 255, 255, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-black {
  color: #fff !important;
  background-color: RGBA(0, 0, 0, var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: #5b2be0 !important;
}

.link-primary:hover, .link-primary:focus {
  color: #4922b3 !important;
}

.link-secondary {
  color: #1d253d !important;
}

.link-secondary:hover, .link-secondary:focus {
  color: #171e31 !important;
}

.link-success {
  color: #187958 !important;
}

.link-success:hover, .link-success:focus {
  color: #136146 !important;
}

.link-info {
  color: #22D3EE !important;
}

.link-info:hover, .link-info:focus {
  color: #4edcf1 !important;
}

.link-warning {
  color: #ffcc43 !important;
}

.link-warning:hover, .link-warning:focus {
  color: #ffd669 !important;
}

.link-danger {
  color: #dc3545 !important;
}

.link-danger:hover, .link-danger:focus {
  color: #b02a37 !important;
}

.link-light {
  color: #f9fafb !important;
}

.link-light:hover, .link-light:focus {
  color: #fafbfc !important;
}

.link-dark {
  color: #111827 !important;
}

.link-dark:hover, .link-dark:focus {
  color: #0e131f !important;
}

.link-white {
  color: #fff !important;
}

.link-white:hover, .link-white:focus {
  color: white !important;
}

.link-black {
  color: #000000 !important;
}

.link-black:hover, .link-black:focus {
  color: black !important;
}

.ratio {
  position: relative;
  width: 100%;
}

.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}

.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.3rem 1.8rem 0 rgba(55, 26, 134, 0.2) !important;
}

.shadow-sm {
  box-shadow: 0 0.2rem 1.25rem 0 rgba(55, 26, 134, 0.1) !important;
}

.shadow-lg {
  box-shadow: 0 0.4rem 2.2rem 0 rgba(55, 26, 134, 0.3) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  --bs-border-width: 1px;
}

.border-2 {
  --bs-border-width: 2px;
}

.border-3 {
  --bs-border-width: 3px;
}

.border-4 {
  --bs-border-width: 4px;
}

.border-5 {
  --bs-border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-6 {
  margin: 4.5rem !important;
}

.m-7 {
  margin: 6rem !important;
}

.m-8 {
  margin: 9rem !important;
}

.m-9 {
  margin: 12rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-6 {
  margin-right: 4.5rem !important;
  margin-left: 4.5rem !important;
}

.mx-7 {
  margin-right: 6rem !important;
  margin-left: 6rem !important;
}

.mx-8 {
  margin-right: 9rem !important;
  margin-left: 9rem !important;
}

.mx-9 {
  margin-right: 12rem !important;
  margin-left: 12rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-6 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

.my-7 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.my-8 {
  margin-top: 9rem !important;
  margin-bottom: 9rem !important;
}

.my-9 {
  margin-top: 12rem !important;
  margin-bottom: 12rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-6 {
  margin-top: 4.5rem !important;
}

.mt-7 {
  margin-top: 6rem !important;
}

.mt-8 {
  margin-top: 9rem !important;
}

.mt-9 {
  margin-top: 12rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-6 {
  margin-right: 4.5rem !important;
}

.me-7 {
  margin-right: 6rem !important;
}

.me-8 {
  margin-right: 9rem !important;
}

.me-9 {
  margin-right: 12rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-6 {
  margin-bottom: 4.5rem !important;
}

.mb-7 {
  margin-bottom: 6rem !important;
}

.mb-8 {
  margin-bottom: 9rem !important;
}

.mb-9 {
  margin-bottom: 12rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-6 {
  margin-left: 4.5rem !important;
}

.ms-7 {
  margin-left: 6rem !important;
}

.ms-8 {
  margin-left: 9rem !important;
}

.ms-9 {
  margin-left: 12rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.m-n6 {
  margin: -4.5rem !important;
}

.m-n7 {
  margin: -6rem !important;
}

.m-n8 {
  margin: -9rem !important;
}

.m-n9 {
  margin: -12rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n5 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.mx-n6 {
  margin-right: -4.5rem !important;
  margin-left: -4.5rem !important;
}

.mx-n7 {
  margin-right: -6rem !important;
  margin-left: -6rem !important;
}

.mx-n8 {
  margin-right: -9rem !important;
  margin-left: -9rem !important;
}

.mx-n9 {
  margin-right: -12rem !important;
  margin-left: -12rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.my-n6 {
  margin-top: -4.5rem !important;
  margin-bottom: -4.5rem !important;
}

.my-n7 {
  margin-top: -6rem !important;
  margin-bottom: -6rem !important;
}

.my-n8 {
  margin-top: -9rem !important;
  margin-bottom: -9rem !important;
}

.my-n9 {
  margin-top: -12rem !important;
  margin-bottom: -12rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.mt-n6 {
  margin-top: -4.5rem !important;
}

.mt-n7 {
  margin-top: -6rem !important;
}

.mt-n8 {
  margin-top: -9rem !important;
}

.mt-n9 {
  margin-top: -12rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -3rem !important;
}

.me-n6 {
  margin-right: -4.5rem !important;
}

.me-n7 {
  margin-right: -6rem !important;
}

.me-n8 {
  margin-right: -9rem !important;
}

.me-n9 {
  margin-right: -12rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -3rem !important;
}

.mb-n6 {
  margin-bottom: -4.5rem !important;
}

.mb-n7 {
  margin-bottom: -6rem !important;
}

.mb-n8 {
  margin-bottom: -9rem !important;
}

.mb-n9 {
  margin-bottom: -12rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -3rem !important;
}

.ms-n6 {
  margin-left: -4.5rem !important;
}

.ms-n7 {
  margin-left: -6rem !important;
}

.ms-n8 {
  margin-left: -9rem !important;
}

.ms-n9 {
  margin-left: -12rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.p-6 {
  padding: 4.5rem !important;
}

.p-7 {
  padding: 6rem !important;
}

.p-8 {
  padding: 9rem !important;
}

.p-9 {
  padding: 12rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-6 {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important;
}

.px-7 {
  padding-right: 6rem !important;
  padding-left: 6rem !important;
}

.px-8 {
  padding-right: 9rem !important;
  padding-left: 9rem !important;
}

.px-9 {
  padding-right: 12rem !important;
  padding-left: 12rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-6 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.py-7 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.py-8 {
  padding-top: 9rem !important;
  padding-bottom: 9rem !important;
}

.py-9 {
  padding-top: 12rem !important;
  padding-bottom: 12rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pt-6 {
  padding-top: 4.5rem !important;
}

.pt-7 {
  padding-top: 6rem !important;
}

.pt-8 {
  padding-top: 9rem !important;
}

.pt-9 {
  padding-top: 12rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pe-6 {
  padding-right: 4.5rem !important;
}

.pe-7 {
  padding-right: 6rem !important;
}

.pe-8 {
  padding-right: 9rem !important;
}

.pe-9 {
  padding-right: 12rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pb-6 {
  padding-bottom: 4.5rem !important;
}

.pb-7 {
  padding-bottom: 6rem !important;
}

.pb-8 {
  padding-bottom: 9rem !important;
}

.pb-9 {
  padding-bottom: 12rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.ps-6 {
  padding-left: 4.5rem !important;
}

.ps-7 {
  padding-left: 6rem !important;
}

.ps-8 {
  padding-left: 9rem !important;
}

.ps-9 {
  padding-left: 12rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.gap-6 {
  gap: 4.5rem !important;
}

.gap-7 {
  gap: 6rem !important;
}

.gap-8 {
  gap: 9rem !important;
}

.gap-9 {
  gap: 12rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.row-gap-6 {
  row-gap: 4.5rem !important;
}

.row-gap-7 {
  row-gap: 6rem !important;
}

.row-gap-8 {
  row-gap: 9rem !important;
}

.row-gap-9 {
  row-gap: 12rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.column-gap-6 {
  column-gap: 4.5rem !important;
}

.column-gap-7 {
  column-gap: 6rem !important;
}

.column-gap-8 {
  column-gap: 9rem !important;
}

.column-gap-9 {
  column-gap: 12rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.3 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6B7280 !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text) !important;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-emphasis {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-emphasis-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-2xl) !important;
  border-top-right-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-2xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-2xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-2xl) !important;
  border-top-left-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    object-fit: contain !important;
  }
  .object-fit-sm-cover {
    object-fit: cover !important;
  }
  .object-fit-sm-fill {
    object-fit: fill !important;
  }
  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-6 {
    margin: 4.5rem !important;
  }
  .m-sm-7 {
    margin: 6rem !important;
  }
  .m-sm-8 {
    margin: 9rem !important;
  }
  .m-sm-9 {
    margin: 12rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-sm-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-sm-8 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .mx-sm-9 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-sm-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-sm-8 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .my-sm-9 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-6 {
    margin-top: 4.5rem !important;
  }
  .mt-sm-7 {
    margin-top: 6rem !important;
  }
  .mt-sm-8 {
    margin-top: 9rem !important;
  }
  .mt-sm-9 {
    margin-top: 12rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-6 {
    margin-right: 4.5rem !important;
  }
  .me-sm-7 {
    margin-right: 6rem !important;
  }
  .me-sm-8 {
    margin-right: 9rem !important;
  }
  .me-sm-9 {
    margin-right: 12rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 4.5rem !important;
  }
  .mb-sm-7 {
    margin-bottom: 6rem !important;
  }
  .mb-sm-8 {
    margin-bottom: 9rem !important;
  }
  .mb-sm-9 {
    margin-bottom: 12rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-6 {
    margin-left: 4.5rem !important;
  }
  .ms-sm-7 {
    margin-left: 6rem !important;
  }
  .ms-sm-8 {
    margin-left: 9rem !important;
  }
  .ms-sm-9 {
    margin-left: 12rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .m-sm-n6 {
    margin: -4.5rem !important;
  }
  .m-sm-n7 {
    margin: -6rem !important;
  }
  .m-sm-n8 {
    margin: -9rem !important;
  }
  .m-sm-n9 {
    margin: -12rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-sm-n6 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .mx-sm-n7 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-sm-n8 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }
  .mx-sm-n9 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-sm-n6 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .my-sm-n7 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-sm-n8 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }
  .my-sm-n9 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-sm-n3 {
    margin-top: -1rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n5 {
    margin-top: -3rem !important;
  }
  .mt-sm-n6 {
    margin-top: -4.5rem !important;
  }
  .mt-sm-n7 {
    margin-top: -6rem !important;
  }
  .mt-sm-n8 {
    margin-top: -9rem !important;
  }
  .mt-sm-n9 {
    margin-top: -12rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .me-sm-n3 {
    margin-right: -1rem !important;
  }
  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .me-sm-n5 {
    margin-right: -3rem !important;
  }
  .me-sm-n6 {
    margin-right: -4.5rem !important;
  }
  .me-sm-n7 {
    margin-right: -6rem !important;
  }
  .me-sm-n8 {
    margin-right: -9rem !important;
  }
  .me-sm-n9 {
    margin-right: -12rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-sm-n6 {
    margin-bottom: -4.5rem !important;
  }
  .mb-sm-n7 {
    margin-bottom: -6rem !important;
  }
  .mb-sm-n8 {
    margin-bottom: -9rem !important;
  }
  .mb-sm-n9 {
    margin-bottom: -12rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-sm-n3 {
    margin-left: -1rem !important;
  }
  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-sm-n5 {
    margin-left: -3rem !important;
  }
  .ms-sm-n6 {
    margin-left: -4.5rem !important;
  }
  .ms-sm-n7 {
    margin-left: -6rem !important;
  }
  .ms-sm-n8 {
    margin-left: -9rem !important;
  }
  .ms-sm-n9 {
    margin-left: -12rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .p-sm-6 {
    padding: 4.5rem !important;
  }
  .p-sm-7 {
    padding: 6rem !important;
  }
  .p-sm-8 {
    padding: 9rem !important;
  }
  .p-sm-9 {
    padding: 12rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-sm-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .px-sm-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-sm-8 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .px-sm-9 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-sm-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-sm-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-sm-8 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .py-sm-9 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pt-sm-6 {
    padding-top: 4.5rem !important;
  }
  .pt-sm-7 {
    padding-top: 6rem !important;
  }
  .pt-sm-8 {
    padding-top: 9rem !important;
  }
  .pt-sm-9 {
    padding-top: 12rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pe-sm-6 {
    padding-right: 4.5rem !important;
  }
  .pe-sm-7 {
    padding-right: 6rem !important;
  }
  .pe-sm-8 {
    padding-right: 9rem !important;
  }
  .pe-sm-9 {
    padding-right: 12rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 4.5rem !important;
  }
  .pb-sm-7 {
    padding-bottom: 6rem !important;
  }
  .pb-sm-8 {
    padding-bottom: 9rem !important;
  }
  .pb-sm-9 {
    padding-bottom: 12rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .ps-sm-6 {
    padding-left: 4.5rem !important;
  }
  .ps-sm-7 {
    padding-left: 6rem !important;
  }
  .ps-sm-8 {
    padding-left: 9rem !important;
  }
  .ps-sm-9 {
    padding-left: 12rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .gap-sm-6 {
    gap: 4.5rem !important;
  }
  .gap-sm-7 {
    gap: 6rem !important;
  }
  .gap-sm-8 {
    gap: 9rem !important;
  }
  .gap-sm-9 {
    gap: 12rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }
  .row-gap-sm-6 {
    row-gap: 4.5rem !important;
  }
  .row-gap-sm-7 {
    row-gap: 6rem !important;
  }
  .row-gap-sm-8 {
    row-gap: 9rem !important;
  }
  .row-gap-sm-9 {
    row-gap: 12rem !important;
  }
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }
  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }
  .column-gap-sm-6 {
    column-gap: 4.5rem !important;
  }
  .column-gap-sm-7 {
    column-gap: 6rem !important;
  }
  .column-gap-sm-8 {
    column-gap: 9rem !important;
  }
  .column-gap-sm-9 {
    column-gap: 12rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    object-fit: contain !important;
  }
  .object-fit-md-cover {
    object-fit: cover !important;
  }
  .object-fit-md-fill {
    object-fit: fill !important;
  }
  .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  .object-fit-md-none {
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-6 {
    margin: 4.5rem !important;
  }
  .m-md-7 {
    margin: 6rem !important;
  }
  .m-md-8 {
    margin: 9rem !important;
  }
  .m-md-9 {
    margin: 12rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-md-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-md-8 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .mx-md-9 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-md-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-md-8 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .my-md-9 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-6 {
    margin-top: 4.5rem !important;
  }
  .mt-md-7 {
    margin-top: 6rem !important;
  }
  .mt-md-8 {
    margin-top: 9rem !important;
  }
  .mt-md-9 {
    margin-top: 12rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-6 {
    margin-right: 4.5rem !important;
  }
  .me-md-7 {
    margin-right: 6rem !important;
  }
  .me-md-8 {
    margin-right: 9rem !important;
  }
  .me-md-9 {
    margin-right: 12rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-6 {
    margin-bottom: 4.5rem !important;
  }
  .mb-md-7 {
    margin-bottom: 6rem !important;
  }
  .mb-md-8 {
    margin-bottom: 9rem !important;
  }
  .mb-md-9 {
    margin-bottom: 12rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-6 {
    margin-left: 4.5rem !important;
  }
  .ms-md-7 {
    margin-left: 6rem !important;
  }
  .ms-md-8 {
    margin-left: 9rem !important;
  }
  .ms-md-9 {
    margin-left: 12rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .m-md-n6 {
    margin: -4.5rem !important;
  }
  .m-md-n7 {
    margin: -6rem !important;
  }
  .m-md-n8 {
    margin: -9rem !important;
  }
  .m-md-n9 {
    margin: -12rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-md-n6 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .mx-md-n7 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-md-n8 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }
  .mx-md-n9 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-md-n6 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .my-md-n7 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-md-n8 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }
  .my-md-n9 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-md-n3 {
    margin-top: -1rem !important;
  }
  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-md-n5 {
    margin-top: -3rem !important;
  }
  .mt-md-n6 {
    margin-top: -4.5rem !important;
  }
  .mt-md-n7 {
    margin-top: -6rem !important;
  }
  .mt-md-n8 {
    margin-top: -9rem !important;
  }
  .mt-md-n9 {
    margin-top: -12rem !important;
  }
  .me-md-n1 {
    margin-right: -0.25rem !important;
  }
  .me-md-n2 {
    margin-right: -0.5rem !important;
  }
  .me-md-n3 {
    margin-right: -1rem !important;
  }
  .me-md-n4 {
    margin-right: -1.5rem !important;
  }
  .me-md-n5 {
    margin-right: -3rem !important;
  }
  .me-md-n6 {
    margin-right: -4.5rem !important;
  }
  .me-md-n7 {
    margin-right: -6rem !important;
  }
  .me-md-n8 {
    margin-right: -9rem !important;
  }
  .me-md-n9 {
    margin-right: -12rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-md-n6 {
    margin-bottom: -4.5rem !important;
  }
  .mb-md-n7 {
    margin-bottom: -6rem !important;
  }
  .mb-md-n8 {
    margin-bottom: -9rem !important;
  }
  .mb-md-n9 {
    margin-bottom: -12rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-md-n3 {
    margin-left: -1rem !important;
  }
  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-md-n5 {
    margin-left: -3rem !important;
  }
  .ms-md-n6 {
    margin-left: -4.5rem !important;
  }
  .ms-md-n7 {
    margin-left: -6rem !important;
  }
  .ms-md-n8 {
    margin-left: -9rem !important;
  }
  .ms-md-n9 {
    margin-left: -12rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .p-md-6 {
    padding: 4.5rem !important;
  }
  .p-md-7 {
    padding: 6rem !important;
  }
  .p-md-8 {
    padding: 9rem !important;
  }
  .p-md-9 {
    padding: 12rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-md-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .px-md-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-md-8 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .px-md-9 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-md-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-md-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-md-8 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .py-md-9 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pt-md-6 {
    padding-top: 4.5rem !important;
  }
  .pt-md-7 {
    padding-top: 6rem !important;
  }
  .pt-md-8 {
    padding-top: 9rem !important;
  }
  .pt-md-9 {
    padding-top: 12rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pe-md-6 {
    padding-right: 4.5rem !important;
  }
  .pe-md-7 {
    padding-right: 6rem !important;
  }
  .pe-md-8 {
    padding-right: 9rem !important;
  }
  .pe-md-9 {
    padding-right: 12rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .pb-md-6 {
    padding-bottom: 4.5rem !important;
  }
  .pb-md-7 {
    padding-bottom: 6rem !important;
  }
  .pb-md-8 {
    padding-bottom: 9rem !important;
  }
  .pb-md-9 {
    padding-bottom: 12rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .ps-md-6 {
    padding-left: 4.5rem !important;
  }
  .ps-md-7 {
    padding-left: 6rem !important;
  }
  .ps-md-8 {
    padding-left: 9rem !important;
  }
  .ps-md-9 {
    padding-left: 12rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .gap-md-6 {
    gap: 4.5rem !important;
  }
  .gap-md-7 {
    gap: 6rem !important;
  }
  .gap-md-8 {
    gap: 9rem !important;
  }
  .gap-md-9 {
    gap: 12rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-3 {
    row-gap: 1rem !important;
  }
  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-5 {
    row-gap: 3rem !important;
  }
  .row-gap-md-6 {
    row-gap: 4.5rem !important;
  }
  .row-gap-md-7 {
    row-gap: 6rem !important;
  }
  .row-gap-md-8 {
    row-gap: 9rem !important;
  }
  .row-gap-md-9 {
    row-gap: 12rem !important;
  }
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-md-3 {
    column-gap: 1rem !important;
  }
  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-md-5 {
    column-gap: 3rem !important;
  }
  .column-gap-md-6 {
    column-gap: 4.5rem !important;
  }
  .column-gap-md-7 {
    column-gap: 6rem !important;
  }
  .column-gap-md-8 {
    column-gap: 9rem !important;
  }
  .column-gap-md-9 {
    column-gap: 12rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    object-fit: contain !important;
  }
  .object-fit-lg-cover {
    object-fit: cover !important;
  }
  .object-fit-lg-fill {
    object-fit: fill !important;
  }
  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-6 {
    margin: 4.5rem !important;
  }
  .m-lg-7 {
    margin: 6rem !important;
  }
  .m-lg-8 {
    margin: 9rem !important;
  }
  .m-lg-9 {
    margin: 12rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-lg-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-lg-8 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .mx-lg-9 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-lg-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-lg-8 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .my-lg-9 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-6 {
    margin-top: 4.5rem !important;
  }
  .mt-lg-7 {
    margin-top: 6rem !important;
  }
  .mt-lg-8 {
    margin-top: 9rem !important;
  }
  .mt-lg-9 {
    margin-top: 12rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-6 {
    margin-right: 4.5rem !important;
  }
  .me-lg-7 {
    margin-right: 6rem !important;
  }
  .me-lg-8 {
    margin-right: 9rem !important;
  }
  .me-lg-9 {
    margin-right: 12rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 4.5rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 6rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 9rem !important;
  }
  .mb-lg-9 {
    margin-bottom: 12rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-6 {
    margin-left: 4.5rem !important;
  }
  .ms-lg-7 {
    margin-left: 6rem !important;
  }
  .ms-lg-8 {
    margin-left: 9rem !important;
  }
  .ms-lg-9 {
    margin-left: 12rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .m-lg-n6 {
    margin: -4.5rem !important;
  }
  .m-lg-n7 {
    margin: -6rem !important;
  }
  .m-lg-n8 {
    margin: -9rem !important;
  }
  .m-lg-n9 {
    margin: -12rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-lg-n6 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .mx-lg-n7 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-lg-n8 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }
  .mx-lg-n9 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-lg-n6 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .my-lg-n7 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-lg-n8 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }
  .my-lg-n9 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-lg-n3 {
    margin-top: -1rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n5 {
    margin-top: -3rem !important;
  }
  .mt-lg-n6 {
    margin-top: -4.5rem !important;
  }
  .mt-lg-n7 {
    margin-top: -6rem !important;
  }
  .mt-lg-n8 {
    margin-top: -9rem !important;
  }
  .mt-lg-n9 {
    margin-top: -12rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .me-lg-n3 {
    margin-right: -1rem !important;
  }
  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .me-lg-n5 {
    margin-right: -3rem !important;
  }
  .me-lg-n6 {
    margin-right: -4.5rem !important;
  }
  .me-lg-n7 {
    margin-right: -6rem !important;
  }
  .me-lg-n8 {
    margin-right: -9rem !important;
  }
  .me-lg-n9 {
    margin-right: -12rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-lg-n6 {
    margin-bottom: -4.5rem !important;
  }
  .mb-lg-n7 {
    margin-bottom: -6rem !important;
  }
  .mb-lg-n8 {
    margin-bottom: -9rem !important;
  }
  .mb-lg-n9 {
    margin-bottom: -12rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-lg-n3 {
    margin-left: -1rem !important;
  }
  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-lg-n5 {
    margin-left: -3rem !important;
  }
  .ms-lg-n6 {
    margin-left: -4.5rem !important;
  }
  .ms-lg-n7 {
    margin-left: -6rem !important;
  }
  .ms-lg-n8 {
    margin-left: -9rem !important;
  }
  .ms-lg-n9 {
    margin-left: -12rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .p-lg-6 {
    padding: 4.5rem !important;
  }
  .p-lg-7 {
    padding: 6rem !important;
  }
  .p-lg-8 {
    padding: 9rem !important;
  }
  .p-lg-9 {
    padding: 12rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-lg-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .px-lg-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-lg-8 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .px-lg-9 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-lg-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-lg-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-lg-8 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .py-lg-9 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pt-lg-6 {
    padding-top: 4.5rem !important;
  }
  .pt-lg-7 {
    padding-top: 6rem !important;
  }
  .pt-lg-8 {
    padding-top: 9rem !important;
  }
  .pt-lg-9 {
    padding-top: 12rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pe-lg-6 {
    padding-right: 4.5rem !important;
  }
  .pe-lg-7 {
    padding-right: 6rem !important;
  }
  .pe-lg-8 {
    padding-right: 9rem !important;
  }
  .pe-lg-9 {
    padding-right: 12rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 4.5rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 6rem !important;
  }
  .pb-lg-8 {
    padding-bottom: 9rem !important;
  }
  .pb-lg-9 {
    padding-bottom: 12rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .ps-lg-6 {
    padding-left: 4.5rem !important;
  }
  .ps-lg-7 {
    padding-left: 6rem !important;
  }
  .ps-lg-8 {
    padding-left: 9rem !important;
  }
  .ps-lg-9 {
    padding-left: 12rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .gap-lg-6 {
    gap: 4.5rem !important;
  }
  .gap-lg-7 {
    gap: 6rem !important;
  }
  .gap-lg-8 {
    gap: 9rem !important;
  }
  .gap-lg-9 {
    gap: 12rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }
  .row-gap-lg-6 {
    row-gap: 4.5rem !important;
  }
  .row-gap-lg-7 {
    row-gap: 6rem !important;
  }
  .row-gap-lg-8 {
    row-gap: 9rem !important;
  }
  .row-gap-lg-9 {
    row-gap: 12rem !important;
  }
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }
  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }
  .column-gap-lg-6 {
    column-gap: 4.5rem !important;
  }
  .column-gap-lg-7 {
    column-gap: 6rem !important;
  }
  .column-gap-lg-8 {
    column-gap: 9rem !important;
  }
  .column-gap-lg-9 {
    column-gap: 12rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    object-fit: contain !important;
  }
  .object-fit-xl-cover {
    object-fit: cover !important;
  }
  .object-fit-xl-fill {
    object-fit: fill !important;
  }
  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-6 {
    margin: 4.5rem !important;
  }
  .m-xl-7 {
    margin: 6rem !important;
  }
  .m-xl-8 {
    margin: 9rem !important;
  }
  .m-xl-9 {
    margin: 12rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-xl-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-xl-8 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .mx-xl-9 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-xl-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-xl-8 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .my-xl-9 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-6 {
    margin-top: 4.5rem !important;
  }
  .mt-xl-7 {
    margin-top: 6rem !important;
  }
  .mt-xl-8 {
    margin-top: 9rem !important;
  }
  .mt-xl-9 {
    margin-top: 12rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-6 {
    margin-right: 4.5rem !important;
  }
  .me-xl-7 {
    margin-right: 6rem !important;
  }
  .me-xl-8 {
    margin-right: 9rem !important;
  }
  .me-xl-9 {
    margin-right: 12rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 4.5rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 6rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 9rem !important;
  }
  .mb-xl-9 {
    margin-bottom: 12rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-6 {
    margin-left: 4.5rem !important;
  }
  .ms-xl-7 {
    margin-left: 6rem !important;
  }
  .ms-xl-8 {
    margin-left: 9rem !important;
  }
  .ms-xl-9 {
    margin-left: 12rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .m-xl-n6 {
    margin: -4.5rem !important;
  }
  .m-xl-n7 {
    margin: -6rem !important;
  }
  .m-xl-n8 {
    margin: -9rem !important;
  }
  .m-xl-n9 {
    margin: -12rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-xl-n6 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .mx-xl-n7 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-xl-n8 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }
  .mx-xl-n9 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-xl-n6 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .my-xl-n7 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-xl-n8 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }
  .my-xl-n9 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n5 {
    margin-top: -3rem !important;
  }
  .mt-xl-n6 {
    margin-top: -4.5rem !important;
  }
  .mt-xl-n7 {
    margin-top: -6rem !important;
  }
  .mt-xl-n8 {
    margin-top: -9rem !important;
  }
  .mt-xl-n9 {
    margin-top: -12rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xl-n3 {
    margin-right: -1rem !important;
  }
  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xl-n5 {
    margin-right: -3rem !important;
  }
  .me-xl-n6 {
    margin-right: -4.5rem !important;
  }
  .me-xl-n7 {
    margin-right: -6rem !important;
  }
  .me-xl-n8 {
    margin-right: -9rem !important;
  }
  .me-xl-n9 {
    margin-right: -12rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-xl-n6 {
    margin-bottom: -4.5rem !important;
  }
  .mb-xl-n7 {
    margin-bottom: -6rem !important;
  }
  .mb-xl-n8 {
    margin-bottom: -9rem !important;
  }
  .mb-xl-n9 {
    margin-bottom: -12rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xl-n5 {
    margin-left: -3rem !important;
  }
  .ms-xl-n6 {
    margin-left: -4.5rem !important;
  }
  .ms-xl-n7 {
    margin-left: -6rem !important;
  }
  .ms-xl-n8 {
    margin-left: -9rem !important;
  }
  .ms-xl-n9 {
    margin-left: -12rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .p-xl-6 {
    padding: 4.5rem !important;
  }
  .p-xl-7 {
    padding: 6rem !important;
  }
  .p-xl-8 {
    padding: 9rem !important;
  }
  .p-xl-9 {
    padding: 12rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xl-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .px-xl-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-xl-8 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .px-xl-9 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xl-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-xl-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-xl-8 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .py-xl-9 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pt-xl-6 {
    padding-top: 4.5rem !important;
  }
  .pt-xl-7 {
    padding-top: 6rem !important;
  }
  .pt-xl-8 {
    padding-top: 9rem !important;
  }
  .pt-xl-9 {
    padding-top: 12rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pe-xl-6 {
    padding-right: 4.5rem !important;
  }
  .pe-xl-7 {
    padding-right: 6rem !important;
  }
  .pe-xl-8 {
    padding-right: 9rem !important;
  }
  .pe-xl-9 {
    padding-right: 12rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 4.5rem !important;
  }
  .pb-xl-7 {
    padding-bottom: 6rem !important;
  }
  .pb-xl-8 {
    padding-bottom: 9rem !important;
  }
  .pb-xl-9 {
    padding-bottom: 12rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .ps-xl-6 {
    padding-left: 4.5rem !important;
  }
  .ps-xl-7 {
    padding-left: 6rem !important;
  }
  .ps-xl-8 {
    padding-left: 9rem !important;
  }
  .ps-xl-9 {
    padding-left: 12rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .gap-xl-6 {
    gap: 4.5rem !important;
  }
  .gap-xl-7 {
    gap: 6rem !important;
  }
  .gap-xl-8 {
    gap: 9rem !important;
  }
  .gap-xl-9 {
    gap: 12rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }
  .row-gap-xl-6 {
    row-gap: 4.5rem !important;
  }
  .row-gap-xl-7 {
    row-gap: 6rem !important;
  }
  .row-gap-xl-8 {
    row-gap: 9rem !important;
  }
  .row-gap-xl-9 {
    row-gap: 12rem !important;
  }
  .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }
  .column-gap-xl-6 {
    column-gap: 4.5rem !important;
  }
  .column-gap-xl-7 {
    column-gap: 6rem !important;
  }
  .column-gap-xl-8 {
    column-gap: 9rem !important;
  }
  .column-gap-xl-9 {
    column-gap: 12rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-6 {
    margin: 4.5rem !important;
  }
  .m-xxl-7 {
    margin: 6rem !important;
  }
  .m-xxl-8 {
    margin: 9rem !important;
  }
  .m-xxl-9 {
    margin: 12rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-xxl-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-xxl-8 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .mx-xxl-9 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-xxl-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-xxl-8 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .my-xxl-9 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-6 {
    margin-top: 4.5rem !important;
  }
  .mt-xxl-7 {
    margin-top: 6rem !important;
  }
  .mt-xxl-8 {
    margin-top: 9rem !important;
  }
  .mt-xxl-9 {
    margin-top: 12rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-6 {
    margin-right: 4.5rem !important;
  }
  .me-xxl-7 {
    margin-right: 6rem !important;
  }
  .me-xxl-8 {
    margin-right: 9rem !important;
  }
  .me-xxl-9 {
    margin-right: 12rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 4.5rem !important;
  }
  .mb-xxl-7 {
    margin-bottom: 6rem !important;
  }
  .mb-xxl-8 {
    margin-bottom: 9rem !important;
  }
  .mb-xxl-9 {
    margin-bottom: 12rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-6 {
    margin-left: 4.5rem !important;
  }
  .ms-xxl-7 {
    margin-left: 6rem !important;
  }
  .ms-xxl-8 {
    margin-left: 9rem !important;
  }
  .ms-xxl-9 {
    margin-left: 12rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -3rem !important;
  }
  .m-xxl-n6 {
    margin: -4.5rem !important;
  }
  .m-xxl-n7 {
    margin: -6rem !important;
  }
  .m-xxl-n8 {
    margin: -9rem !important;
  }
  .m-xxl-n9 {
    margin: -12rem !important;
  }
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xxl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-xxl-n6 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .mx-xxl-n7 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-xxl-n8 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }
  .mx-xxl-n9 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-xxl-n6 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .my-xxl-n7 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-xxl-n8 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }
  .my-xxl-n9 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -3rem !important;
  }
  .mt-xxl-n6 {
    margin-top: -4.5rem !important;
  }
  .mt-xxl-n7 {
    margin-top: -6rem !important;
  }
  .mt-xxl-n8 {
    margin-top: -9rem !important;
  }
  .mt-xxl-n9 {
    margin-top: -12rem !important;
  }
  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xxl-n3 {
    margin-right: -1rem !important;
  }
  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xxl-n5 {
    margin-right: -3rem !important;
  }
  .me-xxl-n6 {
    margin-right: -4.5rem !important;
  }
  .me-xxl-n7 {
    margin-right: -6rem !important;
  }
  .me-xxl-n8 {
    margin-right: -9rem !important;
  }
  .me-xxl-n9 {
    margin-right: -12rem !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-xxl-n6 {
    margin-bottom: -4.5rem !important;
  }
  .mb-xxl-n7 {
    margin-bottom: -6rem !important;
  }
  .mb-xxl-n8 {
    margin-bottom: -9rem !important;
  }
  .mb-xxl-n9 {
    margin-bottom: -12rem !important;
  }
  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -3rem !important;
  }
  .ms-xxl-n6 {
    margin-left: -4.5rem !important;
  }
  .ms-xxl-n7 {
    margin-left: -6rem !important;
  }
  .ms-xxl-n8 {
    margin-left: -9rem !important;
  }
  .ms-xxl-n9 {
    margin-left: -12rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .p-xxl-6 {
    padding: 4.5rem !important;
  }
  .p-xxl-7 {
    padding: 6rem !important;
  }
  .p-xxl-8 {
    padding: 9rem !important;
  }
  .p-xxl-9 {
    padding: 12rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xxl-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .px-xxl-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-xxl-8 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .px-xxl-9 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xxl-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-xxl-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-xxl-8 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .py-xxl-9 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pt-xxl-6 {
    padding-top: 4.5rem !important;
  }
  .pt-xxl-7 {
    padding-top: 6rem !important;
  }
  .pt-xxl-8 {
    padding-top: 9rem !important;
  }
  .pt-xxl-9 {
    padding-top: 12rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pe-xxl-6 {
    padding-right: 4.5rem !important;
  }
  .pe-xxl-7 {
    padding-right: 6rem !important;
  }
  .pe-xxl-8 {
    padding-right: 9rem !important;
  }
  .pe-xxl-9 {
    padding-right: 12rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .pb-xxl-6 {
    padding-bottom: 4.5rem !important;
  }
  .pb-xxl-7 {
    padding-bottom: 6rem !important;
  }
  .pb-xxl-8 {
    padding-bottom: 9rem !important;
  }
  .pb-xxl-9 {
    padding-bottom: 12rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .ps-xxl-6 {
    padding-left: 4.5rem !important;
  }
  .ps-xxl-7 {
    padding-left: 6rem !important;
  }
  .ps-xxl-8 {
    padding-left: 9rem !important;
  }
  .ps-xxl-9 {
    padding-left: 12rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .gap-xxl-6 {
    gap: 4.5rem !important;
  }
  .gap-xxl-7 {
    gap: 6rem !important;
  }
  .gap-xxl-8 {
    gap: 9rem !important;
  }
  .gap-xxl-9 {
    gap: 12rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }
  .row-gap-xxl-6 {
    row-gap: 4.5rem !important;
  }
  .row-gap-xxl-7 {
    row-gap: 6rem !important;
  }
  .row-gap-xxl-8 {
    row-gap: 9rem !important;
  }
  .row-gap-xxl-9 {
    row-gap: 12rem !important;
  }
  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xxl-5 {
    column-gap: 3rem !important;
  }
  .column-gap-xxl-6 {
    column-gap: 4.5rem !important;
  }
  .column-gap-xxl-7 {
    column-gap: 6rem !important;
  }
  .column-gap-xxl-8 {
    column-gap: 9rem !important;
  }
  .column-gap-xxl-9 {
    column-gap: 12rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
/*------------------------------------
  General
------------------------------------*/
html {
  overflow-x: hidden;
}

body {
  position: relative;
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
}

.body-nav-fixed {
  padding-top: 89px;
}

section, .section {
  position: relative;
  z-index: 1;
}

a {
  color: var(--bs-link-color);
  text-decoration: none;
}

a:hover {
  color: var(--bs-link-color);
}

a:focus {
  outline: none;
}

button:focus {
  outline: none;
}

.btn-link {
  text-decoration: none;
}

p {
  line-height: 1.3;
}

section p, .section p {
  line-height: 1.6;
}

.lead {
  margin-bottom: 0.5rem;
}

:focus {
  outline: none;
}

figure {
  margin-bottom: 0;
}

hr {
  border-color: var(--bs-gray-100);
}

[role=button] {
  cursor: pointer;
}

.font-accent {
  font-family: "Open Sans", Helvetica, sans-serif;
}

/*------------------------------------
  Headings
------------------------------------*/
.h1, .h2, .h3, .h4, .h5, .h6, .h7,
h1, h2, h3, h4, h5, h6 {
  line-height: 1.3;
  color: var(--bs-heading-color);
}

.h1 a, .h2 a, .h3 a, .h4 a, .h5 a, .h6 a, .h7 a,
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  color: var(--bs-heading-color);
}

.h1 a:hover, .h2 a:hover, .h3 a:hover, .h4 a:hover, .h5 a:hover, .h6 a:hover, .h7 a:hover,
h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover {
  --bs-bg-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

/*------------------------------------
  Highlight Color
------------------------------------*/
::-moz-selection {
  color: var(--bs-white);
  background-color: var(--bs-primary);
}

::selection {
  color: var(--bs-white);
  background-color: var(--bs-primary);
}

.bg-primary ::-moz-selection {
  color: var(--bs-primary);
  background-color: var(--bs-white);
}

.bg-primary ::selection {
  color: var(--bs-primary);
  background-color: var(--bs-white);
}

.overflow-x-hidden {
  overflow-x: hidden;
}

/*------------------------------------
  hr divider
------------------------------------*/
hr.divider {
  margin-left: auto;
  margin-right: auto;
  border-top-width: 1px;
  border-bottom-width: 1px;
  max-width: 3.25rem;
  height: 0.125rem;
  opacity: 0.85;
}

hr.divider.double-hr {
  border-style: solid;
  height: 0.25rem;
  max-width: 7rem;
  opacity: 0.85;
}

.text-black-white {
  --bs-bg-opacity: 1;
  color: rgba(var(--bs-black-white-rgb), var(--bs-bg-opacity)) !important;
}

.text-white-black {
  --bs-bg-opacity: 1;
  color: rgba(var(--bs-white-black-rgb), var(--bs-bg-opacity)) !important;
}

.text-light-dark {
  --bs-bg-opacity: 1;
  color: rgba(var(--bs-light-dark-rgb), var(--bs-bg-opacity)) !important;
}

.text-dark-light {
  --bs-bg-opacity: 1;
  color: rgba(var(--bs-text-dark-light), var(--bs-bg-opacity)) !important;
}

.bg-light-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-dark-rgb), var(--bs-bg-opacity)) !important;
}

.card-header {
  --bs-card-border-color: var(--bs-border-light-dark);
}

.form-control {
  border-color: var(--bs-border-light-dark);
}

.card-header-tabs .nav-link {
  border-color: var(--bs-border-light-dark);
}

.card-header-tabs .nav-link.active {
  background-color: var(--bs-body-bg);
  border-bottom-color: var(--bs-body-bg);
}

.navbar-light-dark .navbar-nav .show > .nav-link, .navbar-light-dark .navbar-nav .nav-link.active, .navbar-dark-light .navbar-nav .show > .nav-link, .navbar-dark-light .navbar-nav .nav-link.active {
  --bs-bg-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity));
}

.navbar-light-dark .nav-item > .nav-link.dropdown-toggle::after, .navbar-dark-light .nav-item > .nav-link.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpolyline points='112 184 256 328 400 184' fill='none' stroke='%236B7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='48px'/%3e%3c/svg%3e");
}

.navbar-light-dark .dropdown-menu li a.dropdown-toggle::after, .navbar-dark-light .dropdown-menu li a.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpolyline points='112 184 256 328 400 184' fill='none' stroke='%236B7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='48px'/%3e%3c/svg%3e");
}

.navbar-light-dark .dropdown-item, .navbar-dark-light .dropdown-item {
  color: var(--bs-body-color);
}

.navbar-light-dark .dropdown-item:hover, .navbar-dark-light .dropdown-item:hover {
  --bs-bg-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity));
}

.navbar-light-dark .nav-link, .navbar-dark-light .nav-link {
  color: var(--bs-body-color);
}

.navbar-light-dark li:hover .nav-link, .navbar-dark-light li:hover .nav-link {
  --bs-bg-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity));
}

.dark-to-light .dropdown-menu li a.dropdown-toggle::after, .light-to-dark .dropdown-menu li a.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpolyline points='112 184 256 328 400 184' fill='none' stroke='%236B7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='48px'/%3e%3c/svg%3e");
}

.dark-to-light .dropdown-item, .light-to-dark .dropdown-item {
  color: var(--bs-body-color);
}

.dark-to-light .dropdown-item:hover, .light-to-dark .dropdown-item:hover {
  --bs-bg-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity));
}

/*------------------------------------
   Animated
------------------------------------*/
.animated-up-down {
  animation: up-down 1.5s ease-in-out infinite alternate-reverse both;
  -webkit-animation: up-down 1.5s ease-in-out infinite alternate-reverse both;
}

@keyframes up-down {
  0% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
  }
}
.icon-center:hover .play-btn-hover {
  -webkit-animation: animate 2s linear infinite;
  animation: animate 2s linear infinite;
}

@keyframes animate {
  0% {
    box-shadow: 0 0 0 0 rgba(228, 228, 228, 0.7);
  }
  40% {
    box-shadow: 0 0 0 50px rgba(224, 224, 224, 0);
  }
  80% {
    box-shadow: 0 0 0 50px rgba(206, 206, 206, 0);
  }
  100% {
    box-shadow: 0 0 0 rgba(199, 199, 199, 0);
  }
}
.collapse-arrow-end {
  transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
  transition: transform 1s;
  -webkit-transition: transform 1s;
}

.collapse-plus-end {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transition: transform 1s;
  -webkit-transition: transform 1s;
}

.collapsed .collapse-arrow-end, .collapsed .collapse-plus-end {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
}

.particle-move-up {
  position: absolute;
  display: block;
  list-style: none;
  bottom: -150px;
}

.particle-move-up-1, .particle-move-up-2, .particle-move-up-3, .particle-move-up-4, .particle-move-up-5, .particle-move-up-6, .particle-move-up-7, .particle-move-up-8, .particle-move-up-9 {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.particle-move-up-1 {
  left: 25%;
  animation-name: moveups;
  animation-delay: 0s;
  animation-duration: 45s;
}

.particle-move-up-2 {
  left: 10%;
  animation-name: moveups;
  animation-delay: 2s;
  animation-duration: 32s;
}

.particle-move-up-3 {
  left: 70%;
  animation-name: moveups;
  animation-delay: 4s;
  animation-duration: 36s;
}

.particle-move-up-4 {
  left: 40%;
  animation-name: moveups;
  animation-delay: 0s;
  animation-duration: 27s;
}

.particle-move-up-5 {
  left: 65%;
  animation-name: moveups;
  animation-delay: 0s;
  animation-duration: 38s;
}

.particle-move-up-6 {
  left: 75%;
  animation-name: moveups;
  animation-delay: 3s;
  animation-duration: 25s;
}

.particle-move-up-7 {
  left: 35%;
  animation-name: moveups;
  animation-delay: 7s;
  animation-duration: 25s;
}

.particle-move-up-8 {
  left: 50%;
  animation-name: moveups;
  animation-delay: 15s;
  animation-duration: 45s;
}

.particle-move-up-9 {
  right: 15%;
  animation-name: moveups;
  animation-delay: 2s;
  animation-duration: 35s;
}

@keyframes moveups {
  0% {
    transform: translateY(0);
    opacity: 1;
    border-radius: 0;
  }
  100% {
    transform: translateY(-1000px);
    opacity: 0;
    border-radius: 50%;
  }
}
/*----------------------------------
  Avatars
------------------------------------*/
.avatar-xs {
  width: 1.5625rem;
  height: 1.5625rem;
}

.avatar-sm {
  width: 3.125rem;
  height: 3.125rem;
}

.avatar-md {
  width: 4.6875rem;
  height: 4.6875rem;
}

.avatar-lg {
  width: 6.25rem;
  height: 6.25rem;
}

.avatar-xl {
  width: 10rem;
  height: 10rem;
}

.avatar-group img {
  margin-right: -1.5rem;
}

.avatar-group img:hover {
  margin-top: -0.5rem;
}

/*------------------------------------
	Back to top
------------------------------------*/
.back-top {
  visibility: hidden;
  border-radius: 0.375rem;
  opacity: 0;
  transition: opacity 0.3s 0s, visibility 0s 0.3s;
}

.back-top.backtop-is-visible {
  transition: opacity 0.3s 0s, visibility 0s 0s;
  z-index: 99;
  visibility: visible;
  opacity: 1;
}

.back-top.backtop-fade-out {
  transition: opacity 0.3s 0s, visibility 0s 0s;
  opacity: 0.8;
}

.no-touch .back-top:hover {
  transition: opacity 0.3s 0s, visibility 0s 0s;
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity));
  opacity: 1;
}

/*------------------------------------
  Blog
------------------------------------*/
.blog-one-img {
  position: relative;
  overflow: hidden;
}

.blog-one-img img {
  width: 100%;
  transform: scale(1);
  transition: all 0.5s ease-in-out 0s;
}

.blog-one-category {
  position: absolute;
  bottom: 0;
  left: -50%;
  transition: all 0.5s ease-in-out 0s;
}

.blog-one:hover img {
  transform: scale(1.2);
}

.blog-one:hover .blog-one-category {
  left: 0;
}

.blog-one .card-body {
  padding: 2rem;
}

.blog-two-img {
  position: relative;
  overflow: hidden;
}

.blog-two-img a:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  background: rgba(0, 0, 0, 0.2);
  transition: opacity 0.4s linear 0s;
}

.blog-two-img img {
  width: 100%;
}

.blog-two-category {
  position: absolute;
  bottom: 0;
  left: -50%;
  transition: all 0.5s ease-in-out 0s;
}

.blog-two .blog-author {
  position: absolute;
  right: 1rem;
  margin-top: -4.5rem;
}

.blog-two .blog-author img {
  border: #fff 3px solid;
}

.blog-two:hover .blog-two-img a:before {
  opacity: 1;
}

.blog-two:hover .blog-one-category {
  left: 0;
}

.blog-two .card-body {
  padding: 2rem;
}

.blog-three-img {
  position: relative;
  overflow: hidden;
}

.blog-three-img img {
  width: 100%;
  transform: scale(1);
  transition: all 0.5s ease-in-out 0s;
}

.blog-three .post-date {
  position: absolute;
  text-align: center;
  width: 3.5rem;
  top: 0;
  right: 0;
  transition: all 0.5s ease-in-out 0s;
}

.blog-three .post-date .date {
  font-size: 1.5rem;
}

.blog-three:hover img {
  transform: scale(1.2);
}

.blog-three:hover .blog-one-category {
  left: 0;
}

.blog-three .card-body {
  padding: 2rem;
}

/*------------------------------------
  Clients
------------------------------------*/
.brands-item {
  border-width: 1px 0 1px 1px;
  border-color: var(--bs-gray-200);
  border-style: solid;
  padding: 6rem 0;
}

.brands-image {
  margin-left: auto;
  margin-right: auto;
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  filter: gray;
  -webkit-filter: grayscale(100%);
  opacity: 0.5;
  transition: all 0.3s ease;
}

.brands-image:hover {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(0%);
  opacity: 1;
}

/*----------------------------------
  Buttons
------------------------------------*/
.btn {
  cursor: pointer;
  transition: 0.2s ease;
}

.btn.focus, .btn:focus .btn.active:focus, .btn:active:focus {
  outline: 0 none;
  box-shadow: none;
  border-color: transparent;
}

.btn-circle {
  border-radius: 100%;
  padding: 0;
  width: 3.5rem;
  height: 3.5rem;
}

.btn-circle.btn-sm, .btn-group-sm > .btn-circle.btn {
  width: 2rem;
  height: 2rem;
}

.btn-circle.btn-lg, .btn-group-lg > .btn-circle.btn {
  width: 5rem;
  height: 5rem;
}

.btn-pill {
  border-radius: 3.25rem;
}

.btn-link {
  color: var(--bs-primary);
}

.btn-link:hover {
  color: var(--bs-primary);
}

.btn-facebook {
  background: #3b5998;
  border-color: #3b5998;
  color: var(--bs-white);
}

.btn-facebook:hover, .btn-facebook:focus {
  color: var(--bs-white);
  background: rgba(59, 89, 152, 0.9);
}

.btn-twitter {
  background: #1da1f2;
  border-color: #1da1f2;
  color: var(--bs-white);
}

.btn-twitter:hover, .btn-twitter:focus {
  color: var(--bs-white);
  background: rgba(29, 161, 242, 0.9);
}

.btn-youtube {
  background: #ed302f;
  border-color: #ed302f;
  color: var(--bs-white);
}

.btn-youtube:hover, .btn-youtube:focus {
  color: var(--bs-white);
  background: rgba(237, 48, 47, 0.9);
}

.btn-instagram {
  background: #3f729b;
  border-color: #3f729b;
  color: var(--bs-white);
}

.btn-instagram:hover, .btn-instagram:focus {
  color: var(--bs-white);
  background: rgba(63, 114, 155, 0.9);
}

.btn-linkedin {
  background: #3b5998;
  border-color: #3b5998;
  color: var(--bs-white);
}

.btn-linkedin:hover, .btn-linkedin:focus {
  color: var(--bs-white);
  background: rgba(59, 89, 152, 0.9);
}

.btn-github {
  background: #24292e;
  border-color: #24292e;
  color: var(--bs-white);
}

.btn-github:hover, .btn-github:focus {
  color: var(--bs-white);
  background: rgba(36, 41, 46, 0.9);
}

.btn-google {
  background: #d14130;
  border-color: #d14130;
  color: var(--bs-white);
}

.btn-google:hover, .btn-google:focus {
  color: var(--bs-white);
  background: rgba(209, 65, 48, 0.9);
}

.btn-telegram {
  background: #269dd8;
  border-color: #269dd8;
  color: var(--bs-white);
}

.btn-telegram:hover, .btn-telegram:focus {
  color: var(--bs-white);
  background: rgba(38, 157, 216, 0.9);
}

.btn-pinterest {
  background: #c61118;
  border-color: #c61118;
  color: var(--bs-white);
}

.btn-pinterest:hover, .btn-pinterest:focus {
  color: var(--bs-white);
  background: rgba(198, 17, 24, 0.9);
}

.btn-vimeo {
  background: #00adef;
  border-color: #00adef;
  color: var(--bs-white);
}

.btn-vimeo:hover, .btn-vimeo:focus {
  color: var(--bs-white);
  background: rgba(0, 173, 239, 0.9);
}

.btn-vk {
  background: #4c75a3;
  border-color: #4c75a3;
  color: var(--bs-white);
}

.btn-vk:hover, .btn-vk:focus {
  color: var(--bs-white);
  background: rgba(76, 117, 163, 0.9);
}

.btn-accordion.btn-link {
  color: var(--bs-gray-900);
}

.btn-accordion.btn-link:hover {
  color: var(--bs-gray-900);
}

.btn-app {
  padding-left: 1rem;
  padding-right: 1rem;
  min-width: 11.5625rem;
}

.play-btn {
  border-radius: 50%;
  text-align: center;
  line-height: 3.625rem;
  -webkit-animation: animate 2s linear infinite;
  animation: animate 2s linear infinite;
  cursor: pointer;
  width: 3.625rem;
  height: 3.625rem;
  z-index: -1;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.play-btn-icon {
  color: var(--bs-white);
  font-size: 3.75rem;
}

.play-btn-hover {
  border-radius: 50%;
  text-align: center;
  line-height: 3.625rem;
  cursor: pointer;
  width: 3.625rem;
  height: 3.625rem;
  z-index: -1;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.navbar-nav .dropdown-toggle:after {
  border-top: inherit;
  border-right: inherit;
  border-bottom: 0;
  border-left: inherit;
  display: inline-block;
  width: 0.8rem;
  height: 0.8rem;
  background-repeat: no-repeat;
  background-size: 0.8rem 0.8rem;
  vertical-align: middle;
  margin-left: 0.4rem;
}

.navbar-light .nav-item > .dropdown-toggle:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpolyline points='112 184 256 328 400 184' fill='none' stroke='%23374151' stroke-linecap='round' stroke-linejoin='round' stroke-width='48px'/%3e%3c/svg%3e");
}

.navbar-light .nav-item > .nav-link.dropdown-toggle.show:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpolyline points='112 184 256 328 400 184' fill='none' stroke='%235b2be0' stroke-linecap='round' stroke-linejoin='round' stroke-width='48px'/%3e%3c/svg%3e");
}

.navbar-light .nav-item:hover > .nav-link {
  color: var(--bs-link-color);
}

.navbar-light .nav-item:hover > .dropdown-toggle:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpolyline points='112 184 256 328 400 184' fill='none' stroke='%235b2be0' stroke-linecap='round' stroke-linejoin='round' stroke-width='48px'/%3e%3c/svg%3e");
}

.navbar-light .navbar-toggler[aria-expanded=true] .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cline x1='368' y1='368' x2='144' y2='144' fill='none' stroke='%23374151' stroke-linecap='round' stroke-linejoin='round' stroke-width='32px'/%3e%3cline x1='368' y1='144' x2='144' y2='368' fill='none' stroke='%23374151' stroke-linecap='round' stroke-linejoin='round' stroke-width='32px'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-nav .nav-link.show {
  color: var(--bs-white);
}

.navbar-dark .nav-item > .dropdown-toggle:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpolyline points='112 184 256 328 400 184' fill='none' stroke='%23ded5f9' stroke-linecap='round' stroke-linejoin='round' stroke-width='48px'/%3e%3c/svg%3e");
}

.navbar-dark .nav-item > .nav-link.dropdown-toggle.show:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpolyline points='112 184 256 328 400 184' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='48px'/%3e%3c/svg%3e");
}

.navbar-dark .nav-item:hover > .nav-link {
  color: var(--bs-white);
}

.navbar-dark .nav-item:hover > .dropdown-toggle:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpolyline points='112 184 256 328 400 184' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='48px'/%3e%3c/svg%3e");
}

.navbar-dark .nav-item .nav-link.dropdown-toggle.show:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpolyline points='112 184 256 328 400 184' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='48px'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-toggler[aria-expanded=true] .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cline x1='368' y1='368' x2='144' y2='144' fill='none' stroke='%23ded5f9' stroke-linecap='round' stroke-linejoin='round' stroke-width='32px'/%3e%3cline x1='368' y1='144' x2='144' y2='368' fill='none' stroke='%23ded5f9' stroke-linecap='round' stroke-linejoin='round' stroke-width='32px'/%3e%3c/svg%3e");
}

.dropdown-menu li a.dropdown-toggle:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpolyline points='112 184 256 328 400 184' fill='none' stroke='%23374151' stroke-linecap='round' stroke-linejoin='round' stroke-width='48px'/%3e%3c/svg%3e");
}

.dropdown-menu li.show > a, .dropdown-menu li:hover > a {
  color: var(--bs-link-color);
}

.dropdown-menu li.show > a.dropdown-toggle:after, .dropdown-menu li:hover > a.dropdown-toggle:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpolyline points='112 184 256 328 400 184' fill='none' stroke='%235b2be0' stroke-linecap='round' stroke-linejoin='round' stroke-width='48px'/%3e%3c/svg%3e");
}

.dropdown-menu.dropdown-menu-dark li a.dropdown-toggle:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpolyline points='112 184 256 328 400 184' fill='none' stroke='%23ded5f9' stroke-linecap='round' stroke-linejoin='round' stroke-width='48px'/%3e%3c/svg%3e");
}

.dropdown-menu.dropdown-menu-dark li.show > a, .dropdown-menu.dropdown-menu-dark li:hover > a {
  color: var(--bs-white);
}

.dropdown-menu.dropdown-menu-dark li.show > a.dropdown-toggle:after, .dropdown-menu.dropdown-menu-dark li:hover > a.dropdown-toggle:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpolyline points='112 184 256 328 400 184' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='48px'/%3e%3c/svg%3e");
}

.dropdown-menu.dropdown-menu-dark .list-group-item {
  background-color: transparent;
}

.dropdown-menu.dropdown-menu-dark .list-group-item h6, .dropdown-menu.dropdown-menu-dark .list-group-item .h6 {
  color: var(--bs-gray-300);
}

.dropdown-menu.dropdown-menu-dark .list-group-item:hover h6, .dropdown-menu.dropdown-menu-dark .list-group-item:hover .h6 {
  color: var(--bs-white);
}

.side-light li a.dropdown-toggle:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpolyline points='112 184 256 328 400 184' fill='none' stroke='%23374151' stroke-linecap='round' stroke-linejoin='round' stroke-width='48px'/%3e%3c/svg%3e");
}

.side-light li.show > a, .side-light li:hover > a {
  color: var(--bs-link-color);
}

.side-light li.show > a.dropdown-toggle:after, .side-light li:hover > a.dropdown-toggle:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpolyline points='112 184 256 328 400 184' fill='none' stroke='%235b2be0' stroke-linecap='round' stroke-linejoin='round' stroke-width='48px'/%3e%3c/svg%3e");
}

.side-dark li a.dropdown-toggle:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpolyline points='112 184 256 328 400 184' fill='none' stroke='%23ded5f9' stroke-linecap='round' stroke-linejoin='round' stroke-width='48px'/%3e%3c/svg%3e");
}

.side-dark li.show > a, .side-dark li:hover > a {
  color: var(--bs-white);
}

.side-dark li.show > a.dropdown-toggle:after, .side-dark li:hover > a.dropdown-toggle:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpolyline points='112 184 256 328 400 184' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='48px'/%3e%3c/svg%3e");
}

/*------------------------------------
  Collage Images
------------------------------------*/
.image-collage {
  position: relative;
  display: flex;
  justify-content: center;
}

.image-collage a img {
  max-width: 60%;
  border: var(--bs-gray-200) 3px solid;
}

.image-collage a:nth-child(1) {
  position: absolute;
  transform: rotateZ(15deg);
  top: -1rem;
  left: 0;
}

.image-collage a:nth-child(1):hover {
  transform: rotateZ(20deg);
}

.image-collage a:nth-child(2) {
  position: relative;
}

.image-collage a:nth-child(2):hover {
  transform: rotateZ(5deg);
}

.image-collage a:nth-child(3) {
  position: absolute;
  transform: rotateZ(-10deg);
  bottom: -2.1rem;
  left: 0;
}

.image-collage a:nth-child(3):hover {
  transform: rotateZ(-5deg);
}

.image-collage a:nth-child(4) {
  position: absolute;
  transform: rotateZ(-20deg);
  bottom: -4.4rem;
  left: 0;
}

.image-collage a:nth-child(4):hover {
  transform: rotateZ(-15deg);
}

.image-collage a:nth-child(5) {
  position: absolute;
  transform: rotateZ(-30deg);
  bottom: -6.8rem;
  left: 0;
}

.image-collage a:nth-child(5):hover {
  transform: rotateZ(-25deg);
}

/*------------------------------------
  Comments
------------------------------------*/
.comment-list {
  padding-left: 0;
}

.comment-list li {
  list-style: none;
  margin-top: 2rem;
}

.comment-list li .user-comment {
  border-bottom: var(--bs-gray-200) 1px dashed;
  padding-bottom: 1rem;
}

.comment-list li .user-comment .comment-author .avatar {
  float: left;
  margin-right: 1rem;
}

.comment-list li .user-comment .title-comments .authors {
  font-weight: 700;
}

.comment-list li .user-comment .comment-content p {
  margin-bottom: 0;
}

.comment-list li .sub-comment {
  margin-left: 6rem;
}

.comment-list li .sub-comment .sub-comment {
  margin-left: 0;
}

.comment-awaiting-moderation {
  background: var(--bs-yellow);
}

@media (min-width: 576px) {
  .comment-list li .sub-comment .sub-comment {
    margin-left: 6rem;
  }
  .comment-list .title-comments .dates {
    float: right;
  }
}
/*------------------------------------
  Footer
------------------------------------*/
.footer-light {
  color: var(--bs-dark-light);
}

.footer-light .footer-links a {
  color: var(--bs-dark-light);
}

.footer-light .footer-links a:hover {
  color: var(--bs-link-color);
}

.footer-light .footer-links ul li a {
  color: var(--bs-dark-light);
}

.footer-light .footer-links ul li a:hover {
  color: var(--bs-link-color);
}

.footer-light .border-smooth, .footer-light hr {
  border-color: rgba(17, 24, 39, 0.1);
}

.footer-light .before-arrow li::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpolyline points='112 184 256 328 400 184' fill='none' stroke='%23374151' stroke-linecap='round' stroke-linejoin='round' stroke-width='48px'/%3e%3c/svg%3e");
  transform: rotate(-90deg);
}

.footer-dark {
  color: var(--bs-gray-300);
}

.footer-dark .h1, .footer-dark .h2, .footer-dark .h3, .footer-dark .h4, .footer-dark .h5, .footer-dark .h6, .footer-dark .h7,
.footer-dark h1, .footer-dark h2, .footer-dark h3, .footer-dark h4, .footer-dark h5, .footer-dark h6 {
  color: var(--bs-white);
}

.footer-dark .h1 a, .footer-dark .h2 a, .footer-dark .h3 a, .footer-dark .h4 a, .footer-dark .h5 a, .footer-dark .h6 a, .footer-dark .h7 a,
.footer-dark h1 a, .footer-dark h2 a, .footer-dark h3 a, .footer-dark h4 a, .footer-dark h5 a, .footer-dark h6 a {
  color: var(--bs-gray-300);
}

.footer-dark .h1 a:hover, .footer-dark .h2 a:hover, .footer-dark .h3 a:hover, .footer-dark .h4 a:hover, .footer-dark .h5 a:hover, .footer-dark .h6 a:hover, .footer-dark .h7 a:hover,
.footer-dark h1 a:hover, .footer-dark h2 a:hover, .footer-dark h3 a:hover, .footer-dark h4 a:hover, .footer-dark h5 a:hover, .footer-dark h6 a:hover {
  color: var(--bs-white);
}

.footer-dark .footer-links a {
  color: var(--bs-gray-300);
}

.footer-dark .footer-links a:hover {
  color: var(--bs-white);
}

.footer-dark .footer-links ul li a {
  color: var(--bs-gray-300);
}

.footer-dark .footer-links ul li a:hover {
  color: var(--bs-white);
}

.footer-dark .border-smooth, .footer-dark hr {
  border-color: var(--bs-gray-200);
}

.footer-dark .before-arrow li::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpolyline points='112 184 256 328 400 184' fill='none' stroke='%23D1D5DB' stroke-linecap='round' stroke-linejoin='round' stroke-width='48px'/%3e%3c/svg%3e");
  transform: rotate(-90deg);
}

.widget-content {
  padding-bottom: 0.5rem;
}

.social-icon li {
  display: inline-block;
}

.before-arrow li::before {
  display: inline-block;
  width: 0.6rem;
  height: 0.6rem;
  content: "";
  background-repeat: no-repeat;
  background-size: 0.6rem 0.6rem;
  margin-right: 0.6rem;
}

@media (max-width: 767.98px) {
  .footer-copyright .navbar {
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .footer-copyright .navbar .navbar-nav {
    margin-left: inherit !important;
  }
  .footer-copyright .navbar .navbar-nav.footer-nav {
    width: 100%;
    flex-direction: inherit;
    margin-bottom: 1rem;
  }
  .footer-copyright .navbar .navbar-nav.footer-nav li a {
    width: 100%;
    padding: 1rem 0;
  }
  .footer-copyright .navbar .navbar-nav.footer-nav li a:hover {
    color: var(--bs-white);
  }
}
/*----------------------------------
  Forms
------------------------------------*/
.contact-area .icon {
  margin-top: 0.5rem;
  font-size: 2rem;
}

.contact-area > div {
  margin-bottom: 1.5rem;
}

.form-pill {
  border-radius: 3.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

/*------------------------------------
  Five Grid Column
------------------------------------*/
.col-24 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 20%;
}

.offset-24 {
  margin-left: 20%;
}

.col-24 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 20%;
}

.offset-24 {
  margin-left: 20%;
}

@media (min-width: 576px) {
  .col-sm-24 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }
  .offset-sm-24 {
    margin-left: 20%;
  }
}
@media (min-width: 768px) {
  .col-md-24 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }
  .offset-md-24 {
    margin-left: 20%;
  }
}
@media (min-width: 992px) {
  .col-lg-24 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }
  .offset-lg-24 {
    margin-left: 20%;
  }
}
@media (min-width: 1200px) {
  .col-xl-24 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }
  .offset-xl-24 {
    margin-left: 20%;
  }
}
@media (min-width: 1400px) {
  .col-xxl-24 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }
  .offset-xxl-24 {
    margin-left: 20%;
  }
}
.navbar-nav .nav-link {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.navbar-toggler-icon {
  -webkit-transition: background-image 0.5s ease-in-out;
  transition: background-image 0.5s ease-in-out;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.navbar-light-dark.navbar-scrolled {
  background-color: var(--bs-body-bg);
  transition: background-color 0.5s linear;
  box-shadow: 0 0 15px rgba(4, 6, 8, 0.1);
}

.navbar-light .navbar-nav .nav-link.show {
  --bs-bg-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity));
}

.navbar-light .navbar-nav .nav-link.show:before {
  background-color: var(--bs-link-color);
  transform: translateX(-50%) scaleX(1);
  -webkit-transform: translateX(-50%) scaleX(1);
}

.navbar-light.navbar-scrolled {
  background-color: var(--bs-white) !important;
  transition: background-color 0.5s linear;
  box-shadow: 0 0 15px rgba(4, 6, 8, 0.1);
}

.navbar-dark .nav-link {
  --bs-nav-link-color: var(--bs-gray-100);
}

.navbar-dark .nav-link.show {
  color: var(--bs-white);
}

.navbar-dark .nav-link.show:before {
  background-color: var(--bs-white);
  transform: translateX(-50%) scaleX(1);
  -webkit-transform: translateX(-50%) scaleX(1);
}

.navbar-dark.navbar-scrolled {
  background-color: var(--bs-white) !important;
  transition: background-color 0.5s linear;
  box-shadow: 0 0 15px rgba(4, 6, 8, 0.1);
}

.light-to-dark.navbar-scrolled {
  background-color: var(--bs-black) !important;
  transition: background-color 0.5s linear;
  box-shadow: 0 0 15px rgba(4, 6, 8, 0.1);
}

.light-to-dark.navbar-scrolled .logo-brand {
  will-change: filter;
  filter: invert(100%);
}

.dark-to-light.navbar-scrolled {
  background-color: var(--bs-white-black) !important;
  transition: background-color 0.5s linear;
  box-shadow: 0 0 15px rgba(4, 6, 8, 0.1);
}

.dark-to-light.navbar-scrolled .logo-brand {
  will-change: filter;
  filter: invert(100%);
}

.main-nav .nav-item {
  position: relative;
}

.main-nav .nav-item > .nav-link:before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%) scaleX(0);
  -webkit-transform: translateX(-50%) scaleX(0);
  transform-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
  height: 2px;
  width: 1.6rem;
  background-color: var(--bs-primary);
  transition: transform 500ms;
  -webkit-transition: transform 500ms;
}

.main-nav .nav-item > .nav-link.active:before, .main-nav .nav-item > .nav-link:hover:before {
  transform: translateX(-50%) scaleX(1);
  -webkit-transform: translateX(-50%) scaleX(1);
}

.main-nav .nav-item:hover > .nav-link:before {
  transform: translateX(-50%) scaleX(1);
  -webkit-transform: translateX(-50%) scaleX(1);
}

.navbar-dark .nav-item > .nav-link:before {
  background-color: #f9fafb;
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container {
    padding-left: 0;
    padding-right: 0;
  }
  .navbar-expand-lg .navbar-collapse {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .navbar-expand-lg.main-nav {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .navbar-expand-lg.main-nav .navbar-brand {
    padding-left: 1rem;
  }
  .navbar-expand-lg.main-nav .navbar-toggler {
    margin-right: 1rem;
  }
  .navbar-expand-lg.main-nav.light-to-dark .navbar-collapse.show {
    background-color: var(--bs-white);
    border-radius: 0 0 0.5rem 0.5rem;
  }
  .navbar-expand-lg.main-nav.light-to-dark .dropdown-menu .dropdown-submenu a.dropdown-toggle::after, .navbar-expand-lg.main-nav.light-to-dark .dropdown-menu .dropdown-submenu > a::after {
    right: 1rem;
  }
  .navbar-expand-lg.main-nav.light-to-dark.navbar-scrolled .dropdown-menu .dropdown-submenu a.dropdown-toggle::after, .navbar-expand-lg.main-nav.light-to-dark.navbar-scrolled .dropdown-menu .dropdown-submenu > a::after {
    right: 0;
  }
  .navbar-expand-lg.main-nav.light-to-dark.navbar-scrolled .navbar-collapse.show {
    background-color: transparent;
  }
  .navbar-expand-lg.main-nav.dark-to-light .navbar-collapse.show {
    background-color: var(--bs-secondary);
    border-radius: 0 0 0.5rem 0.5rem;
  }
  .navbar-expand-lg.main-nav.dark-to-light .dropdown-menu .dropdown-submenu a.dropdown-toggle::after, .navbar-expand-lg.main-nav.dark-to-light .dropdown-menu .dropdown-submenu > a::after {
    right: 1rem;
  }
  .navbar-expand-lg.main-nav.dark-to-light.navbar-scrolled .dropdown-menu .dropdown-submenu a.dropdown-toggle::after, .navbar-expand-lg.main-nav.dark-to-light.navbar-scrolled .dropdown-menu .dropdown-submenu > a::after {
    right: 0;
  }
  .navbar-expand-lg.main-nav.dark-to-light.navbar-scrolled .navbar-collapse.show {
    background-color: transparent;
  }
  .navbar-expand-lg.main-nav .navbar-collapse {
    margin-top: 1.5rem;
  }
  .navbar-expand-lg.main-nav .nav-link {
    padding-left: 0;
    padding-right: 0;
  }
  .navbar-expand-lg .dropdown-toggle::after {
    position: absolute;
    top: 1rem;
    right: 0;
  }
  .navbar-expand-lg .dropdown-menu .dropdown-submenu a.dropdown-toggle::after, .navbar-expand-lg .dropdown-menu .dropdown-submenu > a::after {
    right: 0;
  }
  .navbar-expand-lg.fixed-top.navbar {
    overflow-y: auto;
    max-height: 100vh;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg.main-nav .nav-item {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .navbar-expand-lg.hover-navbar .nav-item > .nav-link.show:before {
    transform: translateX(-50%) scaleX(0);
    -webkit-transform: translateX(-50%) scaleX(0);
  }
  .navbar-expand-lg.hover-navbar .nav-item:hover > .nav-link.show:before {
    transform: translateX(-50%) scaleX(1);
    -webkit-transform: translateX(-50%) scaleX(1);
  }
  .navbar-expand-lg.hover-navbar.navbar-light .navbar-nav .show > .nav-link {
    color: #374151;
  }
  .navbar-expand-lg.hover-navbar.navbar-light .navbar-nav .nav-item > .nav-link.dropdown-toggle.show::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpolyline points='112 184 256 328 400 184' fill='none' stroke='%23374151' stroke-linecap='round' stroke-linejoin='round' stroke-width='48px'/%3e%3c/svg%3e");
  }
  .navbar-expand-lg.hover-navbar.navbar-light .navbar-nav .nav-item:hover > .nav-link {
    --bs-bg-opacity: 1;
    color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity));
  }
  .navbar-expand-lg.hover-navbar.navbar-light .navbar-nav .nav-item:hover > .nav-link.dropdown-toggle.show::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpolyline points='112 184 256 328 400 184' fill='none' stroke='%235b2be0' stroke-linecap='round' stroke-linejoin='round' stroke-width='48px'/%3e%3c/svg%3e");
  }
  .navbar-expand-lg.hover-navbar.navbar-light .nav-link:focus {
    color: var(--bs-gray-900);
  }
  .navbar-expand-lg.hover-navbar.navbar-light .nav-link:hover {
    --bs-bg-opacity: 1;
    color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity));
  }
  .navbar-expand-lg.hover-navbar.navbar-dark .navbar-nav .show > .nav-link {
    color: #ded5f9;
  }
  .navbar-expand-lg.hover-navbar.navbar-dark .navbar-nav .nav-item > .nav-link.dropdown-toggle.show::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpolyline points='112 184 256 328 400 184' fill='none' stroke='%23ded5f9' stroke-linecap='round' stroke-linejoin='round' stroke-width='48px'/%3e%3c/svg%3e");
  }
  .navbar-expand-lg.hover-navbar.navbar-dark .navbar-nav .nav-item:hover > .nav-link {
    color: var(--bs-white);
  }
  .navbar-expand-lg.hover-navbar.navbar-dark .navbar-nav .nav-item:hover > .nav-link.dropdown-toggle.show::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpolyline points='112 184 256 328 400 184' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='48px'/%3e%3c/svg%3e");
  }
  .navbar-expand-lg.hover-navbar.navbar-dark .nav-link:focus {
    color: var(--bs-white);
  }
  .navbar-expand-lg.hover-navbar.navbar-dark .nav-link:hover {
    color: var(--bs-white);
  }
  .navbar-expand-lg .navbar-nav li .dropdown-menu li:hover > a {
    --bs-bg-opacity: 1;
    color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity));
    background-color: transparent;
  }
}
.main-logo {
  display: flex;
  align-items: center;
}

.main-logo img {
  max-height: 2.5rem;
}

.dark-to-light .main-logo .logo-light {
  display: block;
}

.dark-to-light .main-logo .logo-dark {
  display: none;
}

.dark-to-light.navbar-scrolled .main-logo .logo-light {
  display: none;
}

.dark-to-light.navbar-scrolled .main-logo .logo-dark {
  display: block;
}

.light-to-dark .main-logo .logo-light {
  display: none;
}

.light-to-dark .main-logo .logo-dark {
  display: block;
}

.light-to-dark.navbar-scrolled .main-logo .logo-light {
  display: block;
}

.light-to-dark.navbar-scrolled .main-logo .logo-dark {
  display: none;
}

.dropdown-menu {
  border-radius: 0 0 0.375rem 0.375rem;
  border-width: 3px 0 0;
  box-shadow: 0 0.4rem 2.2rem 0 rgba(27, 30, 36, 0.1);
  --bs-bg-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity));
  box-shadow: 0 0.4rem 2.2rem 0 rgba(27, 30, 36, 0.1);
}

.dropdown-menu-md {
  min-width: 21rem;
}

.dropdown-menu-md a.list-group-item {
  padding: 0;
  margin-bottom: 1.5rem;
  border: 0px solid transparent;
}

.dropdown-menu-md a.list-group-item:hover h6, .dropdown-menu-md a.list-group-item:hover .h6 {
  --bs-bg-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity));
}

.dropdown-menu-md a:last-child.list-group-item {
  margin-bottom: 0.5rem;
}

.dropdown-menu .dropdown-submenu {
  position: relative;
}

.dropdown-menu .dropdown-submenu .dropdown-menu {
  top: -0.675rem;
  left: 100%;
  margin-left: 0;
  margin-right: 0;
}

.dropdown-menu .dropdown-submenu a.dropdown-toggle::after, .dropdown-menu .dropdown-submenu > a::after {
  position: absolute;
  opacity: 0.8;
  top: 1rem;
  right: 1.2rem;
}

.dropdown-menu .dropdown-submenu .dropdown-submenu .dropdown-menu {
  top: -0.675rem;
}

.dropdown-menu .dropdown-submenu .dropdown-reverse {
  left: auto !important;
  right: 100% !important;
}

.dropdown-md-area, .dropdown-lg-area {
  padding: 1.25rem;
}

.dropdown-md-title, .dropdown-lg-title {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  font-weight: 500;
}

.dropdown-md .dropdown-item, .dropdown-lg .dropdown-item {
  border-radius: 0.5rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.header-banner {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  height: 100%;
  min-height: 18rem;
}

.header-banner-content {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.drop-icon {
  align-self: center;
  margin-right: 0.75rem;
  padding: 0.35rem 0.75rem 0.5rem;
}

.drop-text {
  align-self: center;
}

.drop-text p {
  margin-bottom: 0;
  white-space: initial;
}

@media (max-width: 991.98px) {
  .navbar-expand-lg .dropdown-menu.show {
    border-left-width: 0.2rem;
    border-left-style: solid;
    --bs-bg-opacity: 1;
    border-left-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity));
    border-top: 0;
    border-bottom: 0;
    border-right: 0;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    box-shadow: inherit;
  }
  .navbar-expand-lg .dropdown-menu .dropdown-submenu .dropdown-menu {
    margin-left: 1.5rem;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg .dropdown-menu {
    transition: all 0.2s ease-in-out;
    -webkit-transform: translateY(1rem);
    transform: translateY(1rem);
    margin: 0;
    display: block;
    opacity: 0;
    visibility: hidden;
  }
  .navbar-expand-lg .dropdown-menu-lg-center {
    transition: all 0.2s ease-in-out;
    left: 50%;
    right: auto;
    -webkit-transform: translate(-50%, 2rem);
    transform: translate(-50%, 2rem);
  }
  .navbar-expand-lg .dropdown-menu-lg-center.show {
    -webkit-transform: translate(-50%, 0rem) !important;
    transform: translate(-50%, 0rem) !important;
  }
  .navbar-expand-lg .dropdown-menu a.dropdown-toggle:after {
    transform: rotate(-90deg);
  }
  .navbar-expand-lg .dropdown-menu.dropdown-menu-dark a.dropdown-toggle:after {
    transform: rotate(-90deg);
  }
  .navbar-expand-lg .dropdown-menu.show {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    display: block;
    opacity: 1;
    visibility: visible;
  }
  .navbar-expand-lg.hover-navbar .navbar-nav li .dropdown-menu.show {
    -webkit-transform: translateY(1rem);
    transform: translateY(1rem);
    display: block;
    opacity: 0;
    visibility: hidden;
  }
  .navbar-expand-lg.hover-navbar .navbar-nav li .dropdown-menu-lg-center.show {
    -webkit-transform: translate(-50%, 1rem);
    transform: translate(-50%, 1rem);
  }
  .navbar-expand-lg.hover-navbar .navbar-nav li .dropdown-menu li.show > a, .navbar-expand-lg.hover-navbar .navbar-nav li .dropdown-menu li:focus > a {
    --bs-bg-opacity: 1;
    color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity));
  }
  .navbar-expand-lg.hover-navbar .navbar-nav li .dropdown-menu li.show > a.dropdown-toggle:after, .navbar-expand-lg.hover-navbar .navbar-nav li .dropdown-menu li:focus > a.dropdown-toggle:after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpolyline points='112 184 256 328 400 184' fill='none' stroke='%23374151' stroke-linecap='round' stroke-linejoin='round' stroke-width='48px'/%3e%3c/svg%3e");
  }
  .navbar-expand-lg.hover-navbar .navbar-nav li .dropdown-menu li:hover > a {
    --bs-bg-opacity: 1;
    color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity));
  }
  .navbar-expand-lg.hover-navbar .navbar-nav li .dropdown-menu li:hover > a.dropdown-toggle:after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpolyline points='112 184 256 328 400 184' fill='none' stroke='%235b2be0' stroke-linecap='round' stroke-linejoin='round' stroke-width='48px'/%3e%3c/svg%3e");
  }
  .navbar-expand-lg.hover-navbar .navbar-nav li .dropdown-menu.dropdown-menu-dark li.show > a {
    color: #D1D5DB;
  }
  .navbar-expand-lg.hover-navbar .navbar-nav li .dropdown-menu.dropdown-menu-dark li.show > a.dropdown-toggle:after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpolyline points='112 184 256 328 400 184' fill='none' stroke='%23ded5f9' stroke-linecap='round' stroke-linejoin='round' stroke-width='48px'/%3e%3c/svg%3e");
  }
  .navbar-expand-lg.hover-navbar .navbar-nav li .dropdown-menu.dropdown-menu-dark li:hover > a {
    color: #fff;
  }
  .navbar-expand-lg.hover-navbar .navbar-nav li .dropdown-menu.dropdown-menu-dark li:hover > a.dropdown-toggle:after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpolyline points='112 184 256 328 400 184' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='48px'/%3e%3c/svg%3e");
  }
  .navbar-expand-lg.hover-navbar .navbar-nav li:hover > .dropdown-menu {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    display: block;
    opacity: 1;
    visibility: visible;
  }
  .navbar-expand-lg.hover-navbar .navbar-nav li:hover > .dropdown-menu-lg-center {
    -webkit-transform: translate(-50%, 1rem);
    transform: translate(-50%, 1rem);
  }
  .navbar-expand-lg.hover-navbar .navbar-nav li:hover > .dropdown-menu.show {
    display: block;
    opacity: 1;
    visibility: visible;
  }
  .navbar-expand-lg.hover-navbar .navbar-nav > li:hover > .dropdown-menu.show {
    -webkit-transform: translate(-50%, -0.6rem);
    transform: translate(-50%, -0.6rem);
  }
}
@media (min-width: 768px) {
  .dropdown-md {
    min-width: 28rem;
  }
  .dropdown-lg {
    min-width: 38rem;
  }
}
@media (min-width: 992px) {
  .main-nav .nav-item > .nav-link:before {
    bottom: 1.25rem;
  }
  .dropdown-md {
    min-width: 38rem;
  }
  .dropdown-lg {
    min-width: 52rem;
  }
}
/*------------------------------------
  Kit
------------------------------------*/
.masthead {
  text-align: center;
  bottom: 2rem;
  position: absolute;
  width: 100%;
  left: 0;
}

.mouse-icon {
  margin-left: auto;
  margin-right: auto;
  width: 3px;
  padding: 0.55rem 0.75rem;
  height: 1.5rem;
  border: var(--bs-white) 2px solid;
  border-radius: 1.5rem;
  opacity: 0.8;
  box-sizing: content-box;
}

.scroller {
  width: 3px;
  height: 0.6rem;
  border-radius: 25%;
  background-color: var(--bs-white);
  animation-name: scroll;
  animation-duration: 1.5s;
  animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
  animation-iteration-count: infinite;
}

@keyframes scroll {
  0% {
    opacity: 0;
  }
  10% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(15px);
    opacity: 0;
  }
}
.scribble:not(.position-relative) {
  position: absolute;
}

.scribble svg {
  background: none !important;
}

.scale-2 svg {
  transform: scale(2);
}

.scale-3 svg {
  transform: scale(3);
}

.scale-4 svg {
  transform: scale(4);
}

.scale-5 svg {
  transform: scale(5);
}

.scale-6 svg {
  transform: scale(6);
}

.scale-7 svg {
  transform: scale(7);
}

.scale-8 svg {
  transform: scale(8);
}

.showcase-img {
  position: relative;
  min-height: 20rem;
  background-size: cover;
}

.icon-center {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.rotate-45 { /*rtl:begin:ignore*/
  transform: rotate(45deg);
  /*rtl:end:ignore*/
}

.rotate-90 { /*rtl:begin:ignore*/
  transform: rotate(90deg);
  /*rtl:end:ignore*/
}

.rotate-135 { /*rtl:begin:ignore*/
  transform: rotate(135deg);
  /*rtl:end:ignore*/
}

.rotate-180 { /*rtl:begin:ignore*/
  transform: rotate(180deg);
  /*rtl:end:ignore*/
}

.rotate-225 { /*rtl:begin:ignore*/
  transform: rotate(225deg);
  /*rtl:end:ignore*/
}

.rotate-270 { /*rtl:begin:ignore*/
  transform: rotate(270deg);
  /*rtl:end:ignore*/
}

.rotate-315 { /*rtl:begin:ignore*/
  transform: rotate(315deg);
  /*rtl:end:ignore*/
}

.text-shadow {
  text-shadow: 0em 0.1em 2em rgba(0, 0, 0, 0.4);
}

.bg-gradient-primary {
  background-color: var(--bs-primary);
  background-image: linear-gradient(130deg, #4922b3 15%, #5b2be0 40%, #5b2be0 60%, #7c55e6 100%) !important;
}

.bg-gradient-secondary {
  background-color: var(--bs-secondary);
  background-image: linear-gradient(130deg, #171e31 15%, #1d253d 40%, #1d253d 60%, #0c0f18 100%) !important;
}

/*------------------------------------
 Mobile menu
------------------------------------*/
.sidenav-body-open {
  position: fixed;
  width: 100%;
  z-index: 1449;
}

.sidenav-body-open .back-menu-start {
  right: 0;
  left: 17.5rem;
}

.sidenav-body-open .back-menu-start span {
  display: block;
}

.sidenav-body-open .back-menu-end {
  left: 0;
  right: 17.5rem;
}

.sidenav-body-open .back-menu-end span {
  float: right;
  display: block;
  margin-left: auto;
}

.sidenav-body-open:before {
  content: "";
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1410;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1400;
}

.sidenav-body-open .back-menu {
  position: fixed;
  visibility: visible;
  opacity: 1;
  text-decoration: none;
  top: 0;
  z-index: 1451;
  color: #fff;
  padding: 0;
  height: 100%;
  cursor: pointer;
}

.menu-mobile {
  transition: all 0.5s ease;
  position: fixed;
  top: 0;
  overflow: hidden;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 0;
  height: 100%;
  overflow: auto;
  z-index: 1452;
}

.menu-mobile a {
  display: block;
  background: transparent;
  cursor: pointer;
  border: 0;
  font-size: 0.875rem;
  font-family: "Poppins", sans-serif;
  letter-spacing: 1px;
  padding: 0.75rem 1rem;
  font-weight: 500;
  text-decoration: none;
  position: relative;
  z-index: 2000;
}

.menu-mobile .mobile-copyright a {
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  padding: inherit;
}

.push {
  transition: all 0.5s ease;
}

.push-start {
  left: 0;
}

.push-start.push-open {
  width: 17.5rem;
}

.push-end {
  right: 0;
}

.push-end.push-open {
  width: 17.5rem;
}

.back-menu {
  opacity: 0;
  visibility: hidden;
}

.back-menu span {
  display: none;
}

.back-menu .open {
  padding: 1rem;
}

.side-sign {
  text-align: center;
}

.side-sign a {
  display: inline;
}

.social-icons {
  margin-top: 1rem;
  text-align: center;
}

.social-icons a {
  display: inline;
  max-width: 20%;
}

.sidenav-menu .navbar-header, .sidenav-menu .navbar-nav, .sidenav-menu .navbar-nav > li {
  float: none;
}

.sidenav-menu {
  margin-bottom: 1rem;
}

.sidenav-menu a.caret-collapse:after {
  font-size: inherit;
  text-rendering: auto;
  float: right;
  transform: rotate(0deg);
  transition: all linear 0.25s;
}

.sidenav-menu .navbar {
  padding: 0;
}

.sidenav-menu .navbar-nav {
  width: 100%;
}

.sidenav-menu .navbar-nav .dropdown-toggle:after {
  float: right;
  margin-right: 0 !important;
}

.sidenav-menu .navbar-collapse {
  padding-left: 0;
  padding-right: 0;
}

.sidenav-menu .dropdown a.show {
  --bs-bg-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity));
}

.sidenav-menu li.show > a.dropdown-toggle {
  --bs-bg-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity));
}

.sidenav-menu .dropdown-item:hover, .sidenav-menu .dropdown-item:focus, .sidenav-menu .dropdown-item:active {
  background: transparent;
  --bs-bg-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity));
}

.sidenav-menu .dropdown-menu {
  padding-top: 0;
  padding-left: 0.875rem;
  border: 0;
  box-shadow: none;
  position: static;
  float: none;
  min-width: 0;
}

.sidenav-menu .dropdown-menu.show {
  border-left-width: 0 !important;
}

.sidenav-menu .dropdown-menu li a {
  white-space: normal;
}

.sidenav-menu .dropdown-submenu .dropdown-menu {
  border-radius: 0;
  box-shadow: none;
  border-left: 0;
  border-right: 0;
}

.logo-sidenav {
  min-height: 4.5rem;
  text-align: center;
}

.logo-sidenav a::before {
  content: "";
  width: 5rem;
  height: 1px;
  background: var(--bs-link-color);
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -2.5rem;
}

.list-group-level1 {
  padding-left: 0.5rem;
  border-top: #E5E7EB 1px solid;
}

.list-group-level2 {
  padding-left: 1rem;
  border-top: #E5E7EB 1px solid;
}

/*------------------------------------
 Sidebar
------------------------------------*/
.side-links li a, .side-link li a, .sidebar-link li a {
  color: var(--bs-gray-600);
}

.side-links li a.caret-collapse, .side-link li a.caret-collapse, .sidebar-link li a.caret-collapse {
  --bs-bg-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.side-links li a.caret-collapse.collapsed, .side-link li a.caret-collapse.collapsed, .sidebar-link li a.caret-collapse.collapsed {
  color: var(--bs-gray-700);
}

.side-links li a.caret-collapse.collapsed:hover, .side-link li a.caret-collapse.collapsed:hover, .sidebar-link li a.caret-collapse.collapsed:hover {
  --bs-bg-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.side-links li a:hover, .side-links li a.active, .side-link li a:hover, .side-link li a.active, .sidebar-link li a:hover, .sidebar-link li a.active {
  background-color: transparent;
  --bs-bg-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.side-links > a, .side-link > a, .sidebar-link > a {
  color: var(--bs-gray-600);
}

.side-links > a:hover, .side-link > a:hover, .sidebar-link > a:hover {
  --bs-bg-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.sidebar-link li a {
  display: block;
  font-size: 1rem;
  padding: 0.5rem 0;
}

/*----------------------------------
  Modals
------------------------------------*/
.modal-content {
  box-shadow: 0 0.2rem 1.25rem 0 rgba(55, 26, 134, 0.1);
}

@media (min-width: 576px) {
  .modal-content {
    box-shadow: 0 0.3rem 1.8rem 0 rgba(55, 26, 134, 0.2);
  }
}
/*------------------------------------
  Portfolio
------------------------------------*/
.portfolio-nav-item {
  display: inline-block;
  cursor: pointer;
}

.portfolio-nav-item.active {
  color: var(--bs-primary);
}

.portfolio-nav-item:not(:last-child)::after {
  content: "|";
  font-size: 0.71429rem;
  margin: 0 4px;
  position: relative;
  top: -2px;
  line-height: inherit;
  display: inline-block;
  vertical-align: middle;
  color: transparent;
}

@media (min-width: 992px) {
  .portfolio-nav-item:not(:last-child)::after {
    color: var(--bs-gray-200);
    margin: 0 0 0 12px;
  }
}
.portfolio-nav a {
  color: var(--bs-dark);
}

.portfolio-nav a.active {
  color: var(--bs-primary);
}

.portfolio-item {
  position: relative;
  overflow: hidden;
  z-index: 2;
}

.portfolio-item:hover {
  cursor: pointer;
}

.portfolio-item:hover .portfolio-info-2, .portfolio-item:hover .portfolio-info-4 {
  opacity: 1;
}

.portfolio-item:hover .portfolio-info-3 {
  transform: scale(1);
}

.portfolio-item:hover .portfolio-info-content-2 h3, .portfolio-item:hover .portfolio-info-content-2 .h3, .portfolio-item:hover .portfolio-info-content-2 h4, .portfolio-item:hover .portfolio-info-content-2 .h4, .portfolio-item:hover .portfolio-info-content-2 h5, .portfolio-item:hover .portfolio-info-content-2 .h5, .portfolio-item:hover .portfolio-info-content-2 h6, .portfolio-item:hover .portfolio-info-content-2 .h6 {
  -ms-transform: translateY(0px);
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}

.portfolio-item:hover .portfolio-info-content-2 small, .portfolio-item:hover .portfolio-info-content-2 .small {
  -ms-transform: translateY(0px);
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}

.portfolio-item:hover .portfolio-info-4 .portfolio-center-icon {
  -ms-transform: translateY(0px);
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}

.portfolio-item:hover .portfolio-image-4, .portfolio-item:hover .portfolio-image-5 {
  transform: scale(1.05, 1.05);
}

.portfolio-item:hover .portfolio-info-5 {
  opacity: 1;
  top: 0;
}

.portfolio-image {
  display: block;
  width: 100%;
  height: auto;
  transition: all 0.2s ease;
}

.portfolio-image-2, .portfolio-image-3, .portfolio-image-4, .portfolio-image-5 {
  display: block;
  width: 100%;
  height: auto;
}

.portfolio-image-4, .portfolio-image-5 {
  -webkit-transition: all 0.25s linear;
  -moz-transition: all 0.25s linear;
  transition: all 0.25s linear;
}

.portfolio-item:hover .portfolio-image {
  transform: scale(1.2);
}

.portfolio-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3.75rem;
  overflow: hidden;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #fff;
  background-color: var(--bs-primary);
  transition: all 0.2s ease;
  transform: translate3d(0, 3.75rem, 0);
}

.portfolio-info small, .portfolio-info .small {
  opacity: 0.7;
}

.portfolio-info-2, .portfolio-info-3, .portfolio-info-4 {
  background-color: rgba(55, 26, 134, 0.7);
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  position: absolute;
}

.portfolio-info-2:before, .portfolio-info-3:before {
  content: "";
  display: block;
  border: rgba(249, 250, 251, 0.8) 5px solid;
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  right: 0.5rem;
  bottom: 0.5rem;
  z-index: 1;
  opacity: 1;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.portfolio-info-2, .portfolio-info-4 {
  opacity: 0;
  -webkit-transition: opacity 0.7s;
  transition: opacity 0.7s;
}

.portfolio-info-3 {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transform: scale(0);
}

.portfolio-info-4 .portfolio-center-icon {
  -ms-transform: translateY(50px);
  -webkit-transform: translateY(50px);
  transform: translateY(50px);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.portfolio-info-5 {
  background-color: rgba(55, 26, 134, 0.7);
  width: 100%;
  left: 0;
  top: 100%;
  height: 100%;
  position: absolute;
  -webkit-transition: top 0.3s;
  transition: top 0.3s;
  -moz-transition: top 0.3s;
  -o-transition: top 0.3s;
  opacity: 0;
}

.portfolio-info-content-2 {
  margin-top: 0px;
  text-align: center;
  top: 40%;
  width: 100%;
  height: 100%;
  position: absolute;
}

.portfolio-info-content-2 h3, .portfolio-info-content-2 .h3, .portfolio-info-content-2 h4, .portfolio-info-content-2 .h4, .portfolio-info-content-2 h5, .portfolio-info-content-2 .h5, .portfolio-info-content-2 h6, .portfolio-info-content-2 .h6 {
  -ms-transform: translateY(-200px);
  -webkit-transform: translateY(-200px);
  transform: translateY(-200px);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.portfolio-info-content-2 small, .portfolio-info-content-2 .small {
  -ms-transform: translateY(220px);
  -webkit-transform: translateY(220px);
  transform: translateY(220px);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.portfolio-info-content-3, .portfolio-info-content-4, .portfolio-info-content-5 {
  margin-top: 0px;
  text-align: center;
  top: 40%;
  width: 100%;
  height: 100%;
  position: absolute;
}

.portfolio-item:hover .portfolio-info {
  transform: translate3d(0, 0, 0);
}

.portfolio-zoom {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-indent: -10000px;
}

.product-one header {
  overflow: hidden;
}

.product-one img {
  transition: all 0.25s linear;
}

.product-one:hover img {
  -webkit-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
}

/*------------------------------------
  Post
------------------------------------*/
.post-content {
  --bs-postcolor: $post-content-color;
  color: var(--bs-postcolor);
}

.post-content p {
  line-height: 1.7rem;
  margin-bottom: 1.5rem;
}

.post-content ul li {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.post-content figure {
  margin-bottom: 1rem;
}

.post-content figure .b-lazy {
  width: inherit;
}

.post-content figure figcaption {
  --bs-figure-color: $figure-caption-color;
  font-family: Verdana, sans-serif;
  text-align: left;
  font-size: 0.875rem;
  font-style: italic;
  font-weight: 400;
  line-height: 1rem;
  color: var(--bs-figure-color);
  padding: 8px 0;
}

.post-content .align-start, .post-content img.align-start, .post-content .alignstart {
  float: left;
  margin-right: 2rem;
  margin-top: 4px;
}

.post-content .align-end, .post-content img.align-end, .post-content .alignend {
  float: right;
  margin-left: 2rem;
  margin-top: 4px;
}

.post-content .align-center, .post-content img.align-center, .post-content .aligncenter {
  text-align: center;
}

.post-content .align-center figcaption, .post-content img.align-center figcaption, .post-content .aligncenter figcaption {
  text-align: center;
}

.dropcaps {
  color: var(--bs-black-white);
  float: left;
  display: block;
  margin-top: 0.7rem;
  margin-right: 0.7rem;
  margin-bottom: 0;
  margin-left: 0;
  padding: 2px 10px 5px;
  line-height: 3.5rem;
  text-align: center;
  text-transform: uppercase;
  min-width: 3.5rem;
  min-height: 3.5rem;
  font-size: 5rem;
}

@media (max-width: 767.98px) {
  .post-content .align-start, .post-content img.align-start, .post-content .alignstart {
    max-width: 15rem;
  }
  .post-content .align-end, .post-content img.align-end, .post-content .alignend {
    max-width: 15rem;
  }
}
.post-meta {
  border-top: var(--bs-gray-200) 1px dashed;
  border-bottom: var(--bs-gray-200) 1px dashed;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.u-blockquote, .wp-block-quote {
  border-left: var(--bs-primary) 3px solid;
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-dark-rgb), var(--bs-bg-opacity)) !important;
  padding: 1rem;
}

.u-blockquote::before, .wp-block-quote::before {
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 270.000000 270.000000'%3e%3cg transform='translate%280.000000,270.000000%29 scale%280.100000,-0.100000%29' fill='%235b2be0' stroke='none'%3e%3cpath d='M920 2182 c-290 -124 -482 -341 -540 -610 -30 -140 -40 -296 -40 -644 l0 -328 370 0 370 0 0 370 0 370 -181 0 -181 0 7 63 c26 243 129 387 342 477 35 15 66 29 69 32 7 7 -132 298 -143 298 -4 0 -37 -13 -73 -28z'/%3e%3cpath d='M2179 2186 c-249 -103 -442 -295 -520 -516 -50 -142 -61 -247 -66 -677 l-5 -393 371 0 371 0 0 370 0 370 -181 0 -181 0 7 53 c21 170 67 281 150 363 51 49 143 107 215 134 19 7 39 17 44 21 10 9 -124 298 -139 298 -5 0 -35 -10 -66 -23z'/%3e%3c/g%3e%3c/svg%3e");
  position: absolute;
  opacity: 0.8;
  width: 2rem;
  height: 2rem;
}

.u-blockquote p, .u-blockquote footer, .wp-block-quote p, .wp-block-quote footer {
  margin-left: 3.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.breadcrumb-dark li a {
  color: var(--bs-light);
}

.breadcrumb-dark li a:hover {
  color: var(--bs-white);
}

.breadcrumb-dark li.breadcrumb-item.active {
  color: var(--bs-white);
}

.breadcrumb-dark li.breadcrumb-item + .breadcrumb-item {
  padding-left: 0;
}

.breadcrumb-dark li.breadcrumb-item + .breadcrumb-item::before {
  color: var(--bs-gray-200);
}

.pagination-circle li a {
  border-radius: 50% !important;
  margin-right: 1rem;
}

/*------------------------------------
  Preloader
------------------------------------*/
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
}

.loaded-success .preloader {
  visibility: hidden;
  transition: all 1s ease-in-out;
}

/*------------------------------------
  Pricing
------------------------------------*/
.pricing-table-one {
  position: relative;
}

.pricing-table-two {
  position: relative;
  margin-bottom: 1rem;
}

.pricing-table-two .pricing-header {
  position: relative;
  padding: 3rem 4.5rem 1rem;
}

.pricing-table-two .pricing-header .pricing-fee {
  display: block;
  line-height: 0.84;
}

.pricing-table-two .pricing-header .pricing-title {
  opacity: 0.8;
}

.pricing-table-two .pricing-header .sub-head {
  line-height: 1.5;
  margin-bottom: 0.5rem;
}

.pricing-table-two .pricing-header .pricing-decoration {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.pricing-table-two .pricing-content {
  padding: 0 4.5rem 3rem;
}

.pricing-table-two .pricing-content .disabled .u-icon {
  color: #9d80ec;
  background-color: rgba(157, 128, 236, 0.1);
}

.pricing-table-two .disabled {
  color: #9d80ec;
}

.pricing-table-three {
  position: relative;
  margin-bottom: 1rem;
}

.pricing-table-three .pricing-header {
  position: relative;
  padding: 4.5rem 1.5rem;
}

.pricing-table-three .pricing-header .pricing-fee {
  line-height: 0.84;
}

.pricing-table-three .pricing-header .pricing-title {
  opacity: 0.8;
  margin-top: 1rem;
}

.pricing-table-three .pricing-header .pricing-decoration {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.pricing-table-three .pricing-content {
  padding: 1.5rem;
}

.pricing-table-three .pricing-content .disabled .u-icon {
  color: #9d80ec;
  background-color: rgba(157, 128, 236, 0.1);
}

.pricing-table-three .disabled {
  color: #9d80ec;
}

.line-after {
  height: 0.2rem;
  width: 100%;
}

.js-yearly {
  display: none;
}

.slideToggle {
  display: flex;
  justify-content: center;
}

.slideToggle i {
  margin: 0 1rem;
}

.form-switch {
  align-items: center;
  display: flex;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  justify-content: space-between;
  margin-bottom: 1.25rem;
}

.form-switch i {
  position: relative;
  display: inline-block;
  width: 6.25rem;
  height: 1.875rem;
  border: 1px solid #E5E7EB;
  border-radius: 0.9375rem;
  transition: all 0.3s linear;
}

.form-switch i::after {
  content: "";
  position: absolute;
  left: 0;
  width: 2.5rem;
  height: 1.375rem;
  background-color: var(--bs-primary);
  border-radius: 0.9375rem;
  transform: translate3d(4px, 3px, 0);
  transition: all 0.2s ease-in-out;
}

.form-switch input {
  display: none;
}

.form-switch input:checked + i::after {
  transform: translate3d(54px, 3px, 0);
}

/*------------------------------------
  Process
------------------------------------*/
.process-vertical {
  list-style: none;
  margin: 0;
}

.process-vertical-item {
  display: flex;
}

.process-vertical-item:last-child .process-vertical-icon::before {
  bottom: 0.5rem;
}

.process-vertical-icon {
  position: relative;
  width: 4rem;
  flex-shrink: 0;
  text-align: center;
}

.process-vertical-icon-bg {
  height: 3rem;
  width: 3rem;
  background: var(--bs-light-dark);
  border: var(--bs-border-light-dark) 1px solid;
}

.process-vertical-icon span {
  font-size: 1.3rem;
}

.process-vertical-icon::before {
  content: "";
  position: absolute;
  left: 36%;
  top: 3rem;
  bottom: -2rem;
  border-left: 2px dashed var(--bs-border-light-dark);
}

.process-vertical-content {
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.process-horizontal li {
  list-style-type: none;
  float: left;
  width: 25%;
  position: relative;
  text-align: center;
  font-size: 1.25rem;
}

.process-horizontal li svg {
  border: var(--bs-border-light-dark) 1px solid;
  background: var(--bs-light-dark);
  border-radius: 50%;
}

.process-horizontal li.active, .process-horizontal li:hover {
  color: var(--bs-primary);
  cursor: pointer;
}

.process-horizontal li:before {
  width: 1.875rem;
  height: 1.875rem;
  line-height: 1.875rem;
  border: var(--bs-border-light-dark) solid #ddd;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background: var(--bs-light-dark);
}

.process-horizontal li:after {
  content: "";
  position: absolute;
  width: 100%;
  border-bottom: var(--bs-border-light-dark) 2px dashed;
  top: 1.5rem;
  left: -50%;
  z-index: -1;
}

.process-horizontal li:first-child:after {
  content: none;
}

.process-horizontal i {
  font-size: 1.5625rem;
}

/*----------------------------------
  Progress Bars
------------------------------------*/
.progress-vertical {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  --bs-bg-opacity: .7;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
  height: 12.5rem;
}

.progress-vertical-line {
  position: relative;
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
  height: 0;
  transition: 2s linear;
}

.progress-vertical-line::before {
  content: attr(data-percent) "%";
  position: absolute;
  right: 0px;
  left: 0px;
  padding: 1px 0px;
  color: transparent;
  font-size: 0.9375rem;
  border-radius: 1.5625rem;
  font-weight: bold;
  width: 1.25rem;
  margin: 0 auto;
}

.progress-vertical-line.text-white::before {
  color: var(--bs-white);
  padding-top: 10px;
}

.progress-vertical-value::before {
  content: attr(data-percent);
  font-size: 0.9375rem;
}

/*------------------------------------
	Progress top scroll
------------------------------------*/
.progress-one {
  height: 2px;
}

.progress-two {
  height: 4px;
}

.progress-three {
  height: 5px;
}

.progress-one, .progress-two, .progress-three {
  display: inline-block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background-color: transparent;
  z-index: 99999;
  --bs-bg-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.progress-one .progress-container, .progress-two .progress-container, .progress-three .progress-container {
  width: 100%;
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  display: block;
}

.progress-one .progress-container .progress-bar, .progress-two .progress-container .progress-bar, .progress-three .progress-container .progress-bar {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
  width: 0%;
  display: block;
  height: inherit;
}

.progress-one::-webkit-progress-bar, .progress-two::-webkit-progress-bar, .progress-three::-webkit-progress-bar {
  background-color: rgba(221, 221, 221, 0.05);
}

.progress-one::-webkit-progress-value, .progress-two::-webkit-progress-value, .progress-three::-webkit-progress-value {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.progress-one::-moz-progress-bar, .progress-two::-moz-progress-bar, .progress-three::-moz-progress-bar {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.progress-one:after, .progress-two:after, .progress-three:after {
  background-image: none;
}

.admin-bar .progress-one, .admin-bar .progress-two, .admin-bar .progress-three {
  top: 32px;
  background: rgba(221, 221, 221, 0.05);
}

.progress-area {
  height: 1.125rem;
  --bs-bg-opacity: .7;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
  border-radius: 1.875rem;
}

.progress-area .progress-bar {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.progress-bar {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
  height: 1.125rem;
  border-radius: 1.875rem;
  position: relative;
  width: 0;
  transition: 2s linear;
}

.progress-bar::before {
  content: attr(data-percent) "%";
  position: absolute;
  right: 0px;
  left: 0px;
  padding: 1px 0px;
  color: #fff;
  font-size: 0.9375rem;
  border-radius: 1.5625rem;
  font-weight: bold;
  width: 1.25rem;
  margin: 0px auto;
}

/*----------------------------------
  Reviews
------------------------------------*/
.reviews-one, .reviews-two {
  position: relative;
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-black-rgb), var(--bs-bg-opacity)) !important;
  box-shadow: 0 0.2rem 1.25rem 0 rgba(55, 26, 134, 0.1);
  padding-top: 1.875rem;
  padding-right: 1.875rem;
  padding-bottom: 1.875rem;
  padding-left: 3.75rem;
}

.reviews-one::before, .reviews-two::before {
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 270.000000 270.000000'%3e%3cg transform='translate%280.000000,270.000000%29 scale%280.100000,-0.100000%29' fill='%235b2be0' stroke='none'%3e%3cpath d='M920 2182 c-290 -124 -482 -341 -540 -610 -30 -140 -40 -296 -40 -644 l0 -328 370 0 370 0 0 370 0 370 -181 0 -181 0 7 63 c26 243 129 387 342 477 35 15 66 29 69 32 7 7 -132 298 -143 298 -4 0 -37 -13 -73 -28z'/%3e%3cpath d='M2179 2186 c-249 -103 -442 -295 -520 -516 -50 -142 -61 -247 -66 -677 l-5 -393 371 0 371 0 0 370 0 370 -181 0 -181 0 7 53 c21 170 67 281 150 363 51 49 143 107 215 134 19 7 39 17 44 21 10 9 -124 298 -139 298 -5 0 -35 -10 -66 -23z'/%3e%3c/g%3e%3c/svg%3e");
  position: absolute;
  transform: rotate(0deg);
  opacity: 0.8;
  width: 2rem;
  height: 2rem;
  top: 1rem;
  left: 1rem;
}

.reviews-one-thumb, .reviews-two-thumb {
  width: 3.75rem;
  height: 3.75rem;
}

.reviews-one::after {
  content: "";
  position: absolute;
  bottom: -1.875rem;
  left: 3.75rem;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: var(--bs-white-black) transparent transparent transparent;
  border-top-width: 1.875rem;
  border-left-width: 0;
  border-right-width: 1.875rem;
  border-bottom-width: 0;
  filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.1));
}

.reviews-two::after {
  content: "";
  position: absolute;
  top: -3.75rem;
  left: 1.25rem;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 1.875rem;
  border-color: transparent transparent var(--bs-white-black) transparent;
  filter: drop-shadow(1px -1px 1px rgba(0, 0, 0, 0.1));
}

.reviews-three {
  position: relative;
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-black-rgb), var(--bs-bg-opacity)) !important;
  box-shadow: 0 0.2rem 1.25rem 0 rgba(55, 26, 134, 0.1);
  padding: 1.875rem;
}

.reviews-three-thumb {
  width: 3.75rem;
  height: 3.75rem;
}

.reviews-four, .reviews-five {
  position: relative;
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-black-rgb), var(--bs-bg-opacity)) !important;
  box-shadow: 0 0.2rem 1.25rem 0 rgba(55, 26, 134, 0.1);
  padding: 1.875rem 1.875rem 2.5rem;
}

.reviews-four::after, .reviews-five::after {
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 270.000000 270.000000'%3e%3cg transform='translate%280.000000,270.000000%29 scale%280.100000,-0.100000%29' fill='%235b2be0' stroke='none'%3e%3cpath d='M400 1700 l0 -370 180 0 180 0 0 -45 c0 -116 -47 -250 -123 -347 -36 -47 -159 -129 -238 -158 -33 -13 -58 -26 -57 -30 2 -4 34 -73 72 -153 l69 -145 48 19 c276 108 474 303 553 545 42 130 48 195 53 632 l5 422 -371 0 -371 0 0 -370z'/%3e%3cpath d='M1660 1700 l0 -370 180 0 180 0 0 -34 c0 -69 -31 -193 -65 -262 -47 -96 -129 -172 -247 -230 -49 -23 -93 -44 -99 -46 -5 -2 22 -71 62 -155 l72 -151 79 33 c243 103 439 298 513 512 51 145 57 209 62 656 l5 417 -371 0 -371 0 0 -370z'/%3e%3c/g%3e%3c/svg%3e");
  position: absolute;
  transform: rotate(0deg);
  opacity: 0.8;
  width: 2rem;
  height: 2rem;
  right: 2rem;
  bottom: 1rem;
}

.reviews-four-thumb, .reviews-five-thumb {
  width: 3.75rem;
  height: 3.75rem;
}

.reviews-five {
  position: relative;
  margin-left: 1.875rem;
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-black-rgb), var(--bs-bg-opacity)) !important;
  box-shadow: 0 0.2rem 1.25rem 0 rgba(55, 26, 134, 0.1);
  padding-top: 1.875rem;
  padding-right: 1.875rem;
  padding-bottom: 1.875rem;
  padding-left: 4rem;
}

.thumb-minus {
  margin-top: -2rem;
  margin-left: 2rem;
}

.thumb-start-minus {
  top: 50%;
  margin-top: -1.875rem;
  margin-left: 0;
}

.ratings {
  padding: 0;
  margin: 0;
  display: -ms-flexbox;
  display: flex;
}

.ratings li {
  padding: 0;
  margin: 0 10px 0 0;
  list-style: none;
}

.ratings li svg {
  stroke: #371a86;
}

.ratings li.active {
  color: #ffcc43;
}

.ratings li.active svg .rating-svg {
  stroke: #ffcc43;
}

/*------------------------------------
  Ribbon
------------------------------------*/
.ribbon {
  position: absolute;
  z-index: 1;
  overflow: hidden;
  text-align: right;
}

.ribbon span {
  color: var(--bs-white);
  text-align: center;
  display: block;
  box-shadow: 0 3px 10px -5px rgb(0, 0, 0);
  position: absolute;
}

.ribbon span::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #9d9d9d;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #9d9d9d;
}

.ribbon span::after {
  content: "";
  position: absolute;
  right: 0%;
  top: 100%;
  z-index: -1;
  border-right: 3px solid #9d9d9d;
  border-left: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #9d9d9d;
}

.ribbon-lg {
  font-size: 1.25rem;
  width: 15.625rem;
  height: 15.625rem;
  top: -6px;
}

.ribbon-lg span {
  line-height: 3.125rem;
  width: 12.5rem;
  top: 1.875rem;
}

.ribbon-lg.ribbon-start {
  left: -0.6875rem;
}

.ribbon-lg.ribbon-start span {
  left: -2.5rem;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.ribbon-lg.ribbon-end {
  right: -0.6875rem;
}

.ribbon-lg.ribbon-end span {
  right: -2.5rem;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.ribbon-md {
  font-size: 0.875rem;
  width: 9.375rem;
  height: 9.375rem;
  top: -5px;
}

.ribbon-md span {
  line-height: 1.875rem;
  width: 9.375rem;
  top: 1.75rem;
}

.ribbon-md.ribbon-start {
  left: -7px;
}

.ribbon-md.ribbon-start span {
  left: -1.875rem;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.ribbon-md.ribbon-end {
  right: -7px;
}

.ribbon-md.ribbon-end span {
  right: -1.875rem;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.ribbon-sm {
  font-size: 0.75rem;
  width: 6.25rem;
  height: 6.25rem;
  top: -5px;
}

.ribbon-sm span {
  line-height: 1.25rem;
  width: 6.25rem;
  top: 1.1875rem;
}

.ribbon-sm.ribbon-start {
  left: -6px;
}

.ribbon-sm.ribbon-start span {
  left: -1.25rem;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.ribbon-sm.ribbon-end {
  right: -6px;
}

.ribbon-sm.ribbon-end span {
  right: -1.25rem;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.no-gutters .ribbon-lg.ribbon-start, .no-gutters .ribbon-md.ribbon-start, .no-gutters .ribbon-sm.ribbon-start, .g-0 .ribbon-lg.ribbon-start, .g-0 .ribbon-md.ribbon-start, .g-0 .ribbon-sm.ribbon-start {
  left: -7px;
}

.no-gutters .ribbon-lg.ribbon-end, .no-gutters .ribbon-md.ribbon-end, .no-gutters .ribbon-sm.ribbon-end, .g-0 .ribbon-lg.ribbon-end, .g-0 .ribbon-md.ribbon-end, .g-0 .ribbon-sm.ribbon-end {
  right: -7px;
}

/*------------------------------------
  Services
------------------------------------*/
.three-service {
  position: relative;
}

.four-service-icon {
  position: absolute;
  top: 1.2rem;
  right: 2.5rem;
  max-width: 6.25rem;
  z-index: 1;
  text-align: center;
}

.four-service-icon i {
  font-size: 3rem;
  line-height: 4.0625rem;
}

.four-service-content {
  padding-right: 2.75rem;
}

.four-service .line {
  content: "";
  position: relative;
  display: block;
  width: 6.25rem;
  height: 2px;
  background: var(--bs-primary);
  border-radius: 25%;
  margin-top: 0.9375rem;
  margin-bottom: 5px;
}

.four-service .line::after {
  position: absolute;
  content: "";
  width: 4.375rem;
  height: 2px;
  background: var(--bs-primary);
  bottom: -0.625rem;
  left: 0;
}

.five-service {
  position: relative;
}

.five-service .service-icon {
  border-radius: 50%;
  width: 4.25rem;
  height: 4.25rem;
  padding-top: 1rem;
  background: var(--bs-primary);
  margin: 0 auto;
  position: absolute;
  top: -1.875rem;
  left: 0;
  right: 0;
  z-index: 1;
  transition: all 0.3s ease-out 0s;
}

.five-service .service-icon span i {
  font-size: 2rem;
  transition: all 0.3s ease-out 0s;
}

.five-service .service-icon span svg {
  transition: all 0.3s ease-out 0s;
}

.five-service .read-more {
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.375rem;
  border-radius: 50%;
  background: #fff;
  border: 1px solid var(--bs-border-light-dark);
  font-size: 0.875rem;
  color: #D1D5DB;
  margin: 0 auto;
  position: absolute;
  bottom: -1.0625rem;
  left: 0;
  right: 0;
  transition: all 0.3s ease-out 0s;
}

.five-service .read-more:hover {
  color: var(--bs-primary);
}

.three-features {
  position: relative;
  padding-left: 5rem;
  margin-bottom: 2.1875rem;
}

.three-features .features-icon {
  font-size: 3rem;
  position: absolute;
  left: 0;
  top: 0;
  padding-top: 0.25rem;
}

.three-features.features-end {
  padding-right: 5rem;
  padding-left: 0;
  text-align: right;
}

.three-features.features-end .features-icon {
  right: 0;
  left: auto;
  margin-top: 0;
}

/*----------------------------------
  Tabs
------------------------------------*/
.tab-profile > li > a > div {
  background: var(--bs-light-dark);
}

.tab-profile > li > a.active > div {
  background: var(--bs-white-black);
}

/*------------------------------------
  team
------------------------------------*/
.team, .team2, .team3, .team5 {
  position: relative;
}

.team .team-info .team-info-content, .team2 .team-info .team-info-content, .team3 .team-info .team-info-content, .team5 .team-info .team-info-content {
  padding: 1rem;
  text-align: center;
}

.team .team-info .team-info-content .team-info-social, .team2 .team-info .team-info-content .team-info-social, .team3 .team-info .team-info-content .team-info-social, .team5 .team-info .team-info-content .team-info-social {
  margin-top: 1rem;
}

.team .team-info .team-info-content .team-info-social a, .team2 .team-info .team-info-content .team-info-social a, .team3 .team-info .team-info-content .team-info-social a, .team5 .team-info .team-info-content .team-info-social a {
  margin-right: 0.5rem;
}

.team {
  position: relative;
  overflow: hidden;
}

.team .team-info .team-info-content {
  opacity: 0;
  position: absolute;
  right: 0;
  left: 0;
  bottom: -100%;
  transition: bottom 0.4s ease-in-out 0s;
}

.team .team-info .team-info-content h4, .team .team-info .team-info-content .h4 {
  line-height: 1.2;
}

.team:hover .team-info-content {
  opacity: 1;
  bottom: 0;
}

.team2 {
  position: relative;
  overflow: hidden;
}

.team2 .team-thumb {
  position: relative;
  overflow: hidden;
}

.team2 .team-thumb img {
  transition: 0.35s ease 0.2s;
}

.team2 .team-thumb .view-profile {
  height: 2.75rem;
  width: 2.75rem;
  opacity: 0;
  background-color: var(--bs-white);
  font-size: 1rem;
  padding: 1.125rem;
  border-top-left-radius: 6.25rem;
  display: block;
  opacity: 0;
  position: absolute;
  right: -100%;
  bottom: 0;
  transition: right 0.1s ease 0s;
}

.team2:hover .team-thumb img {
  -webkit-transform: scale(1.1) rotate(5deg);
  transform: scale(1.1) rotate(5deg);
}

.team2:hover .team-thumb .view-profile {
  opacity: 1;
  right: 0;
}

.team3 {
  padding: 1rem 2rem 0;
}

.team3 img {
  transform: scale(1);
  transition: all 0.5s ease-in-out 0s;
}

.team3:hover img {
  transform: scale(1.1);
}

.team3 .team-thumb {
  border: var(--bs-border-light-dark) 3px solid;
  overflow: hidden;
}

.team4 {
  overflow: hidden;
}

.team4 .team-thumb {
  background-color: var(--bs-primary);
  position: relative;
  overflow: hidden;
}

.team4 .team-thumb .team-social {
  padding: 0.5rem 0;
  background-color: var(--bs-white);
  left: 1rem;
  right: 1rem;
  position: absolute;
  text-align: center;
  bottom: 1rem;
}

.team4 .team-thumb .team-social ul li {
  display: inline-block;
  margin-right: 1rem;
}

.team4 .team-thumb .team-social ul li a {
  color: var(--bs-white);
}

.team4:hover .team-social ul li a {
  color: var(--bs-primary);
}

.team5 {
  position: relative;
  overflow: hidden;
}

.team5 img {
  transform: scale(1);
  transition: all 0.5s ease-in-out 0s;
}

.team5:hover img {
  transform: scale(1.1);
}

.team5 .team-thumb {
  overflow: hidden;
}

.team5 .team-info-social {
  text-align: center;
}

.team5 .team-info-social a {
  font-size: 1.2rem;
  margin-right: 1rem;
}

.team5 .team-info-social a:last-child {
  margin-right: 0;
}

/*----------------------------------
  Flickity slider
------------------------------------*/
.flickity-enabled.is-fade .flickity-slider > * {
  pointer-events: none;
  z-index: 0;
}

.flickity-enabled.is-fade .flickity-slider > .is-selected {
  pointer-events: auto;
  z-index: 1;
}

.nav-dark-button .flickity-button {
  background: rgba(197, 198, 208, 0.7);
  color: #fff;
}

.nav-dark-button .flickity-button:hover {
  background: rgb(197, 198, 208);
}

.nav-inset-button .flickity-button.previous {
  left: 1.5rem;
}

.nav-inset-button .flickity-button.next {
  right: 1.5rem;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 33%;
  top: 33%;
  width: 35%;
  height: 35%;
  opacity: 0.8;
}

.slider-item img {
  cursor: pointer;
}

.dot-in-content .flickity-page-dots {
  margin-bottom: 3rem;
}

.nav-light-dots .flickity-page-dots li {
  background-color: #f9fafb;
}

.nav-light-dots .flickity-page-dots li.is-selected {
  background-color: #fff;
}

.hero-slider .flickity-button.previous, .hero-slider2 .flickity-button.previous {
  opacity: 0;
  transition: all 1.2s ease;
}

.hero-slider .flickity-button.next, .hero-slider2 .flickity-button.next {
  opacity: 0;
  transition: all 1.2s ease;
}

.hero-slider:hover .flickity-button.next, .hero-slider:hover .flickity-button.previous, .hero-slider2:hover .flickity-button.next, .hero-slider2:hover .flickity-button.previous {
  opacity: 1;
  transition: all 1.2s ease;
}

.hero-slider .flickity-page-dots, .hero-slider2 .flickity-page-dots {
  bottom: 2rem;
}

.flickity-rtl .reviews-one::before, .flickity-rtl .reviews-two::before {
  transform: rotate(180deg);
}

.flickity-rtl .reviews-four::after, .flickity-rtl .reviews-five::after {
  transform: rotate(180deg);
}

/*------------------------------------
    Filter
------------------------------------*/
.filter-label {
  display: block;
  padding: 0;
  margin-top: 0;
  margin-bottom: 4px;
  color: #95a5a6;
}

.filters-group {
  padding: 0;
  margin: 0 0 4px;
  border: 0;
}

@media screen and (min-width: 768px) {
  .filters-group-wrap {
    display: flex;
    justify-content: space-between;
  }
}
/*------------------------------------
  Jarallax
------------------------------------*/
.jarallax {
  position: relative;
  z-index: 0;
}

.jarallax > .jarallax-img {
  position: absolute;
  object-fit: cover;
  font-family: "object-fit: cover;";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

/*------------------------------------
  Background wrapper
------------------------------------*/
.bg-wrapper-start {
  position: absolute;
  left: 0;
  top: 0;
  right: auto;
  bottom: 0;
}

.bg-wrapper-end {
  position: absolute;
  left: auto;
  top: 0;
  right: 0;
  bottom: 0;
}

.bg-wrapper-top {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: auto;
}

.bg-wrapper-bottom {
  position: absolute;
  left: 0;
  top: auto;
  right: 0;
  bottom: 0;
}

.w-10 {
  width: 10% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.h-10 {
  height: 10% !important;
}

.h-20 {
  height: 20% !important;
}

.h-30 {
  height: 30% !important;
}

.h-40 {
  height: 40% !important;
}

.h-50 {
  height: 50% !important;
}

.h-60 {
  height: 60% !important;
}

.h-70 {
  height: 70% !important;
}

.h-80 {
  height: 80% !important;
}

.h-90 {
  height: 90% !important;
}

@media (max-width: 575.98px) {
  .bg-white-sm-down {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-white-black-rgb), var(--bs-bg-opacity)) !important;
  }
}
@media (max-width: 767.98px) {
  .bg-white-md-down {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-white-black-rgb), var(--bs-bg-opacity)) !important;
  }
}
@media (max-width: 991.98px) {
  .bg-white-lg-down {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-white-black-rgb), var(--bs-bg-opacity)) !important;
  }
}
@media (max-width: 1199.98px) {
  .bg-white-xl-down {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-white-black-rgb), var(--bs-bg-opacity)) !important;
  }
}
@media (max-width: 1399.98px) {
  .bg-white-xxl-down {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-white-black-rgb), var(--bs-bg-opacity)) !important;
  }
}
.bg-white-down {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-black-rgb), var(--bs-bg-opacity)) !important;
}

.start-auto {
  left: auto;
}

.end-auto {
  right: auto;
}

.top-auto {
  top: auto;
}

.bottom-auto {
  bottom: auto;
}

/*------------------------------------
	Border width
------------------------------------*/
.border-smooth {
  border-color: rgba(249, 250, 251, 0.1) !important;
}

.border-smooth-light {
  border-color: rgba(17, 24, 39, 0.1) !important;
}

/*------------------------------------
  Font size
------------------------------------*/
/* Font Size */
.fs-1 {
  font-size: 1rem;
}

.fs-2 {
  font-size: 2rem;
}

.fs-3 {
  font-size: 3rem;
}

.fs-4 {
  font-size: 4rem;
}

.fs-5 {
  font-size: 5rem;
}

.fs-50 {
  font-size: 50%;
}

.fs-60 {
  font-size: 60%;
}

.fs-70 {
  font-size: 70%;
}

.fs-75 {
  font-size: 75%;
}

.fs-80 {
  font-size: 80%;
}

.fs-90 {
  font-size: 90%;
}

/*------------------------------------
  Font weight
------------------------------------*/
.fw-medium {
  font-weight: 500 !important;
}

.fw-semi-bold {
  font-weight: 600 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

/*----------------------------------
  Box Shadow
------------------------------------*/
.shadow-bottom {
  box-shadow: 0 8px 6px -6px rgba(55, 26, 134, 0.3);
}

.shadow-right-bottom {
  box-shadow: 2px 2px 2px rgba(55, 26, 134, 0.3);
}

.collapse-shadow {
  box-shadow: 0 0.02rem 0.1rem 0 #bdaaf3;
}

/*------------------------------------
  Hover
------------------------------------*/
.hover-none .d-hover-block {
  display: none;
}

.hover-none:hover .d-hover-block {
  display: block;
  transition: all 0.3s ease;
}

.hover-box-up {
  transition: all 0.3s ease-in-out;
}

.hover-box-up:hover {
  transform: translateY(-10px);
}

.hover-box-down {
  transition: all 0.3s ease-in-out;
}

.hover-box-down:hover {
  transform: translateY(10px);
}

.hover-button-up {
  transition: all 0.1s ease-in-out;
}

.hover-button-up:hover {
  box-shadow: 0 0.2rem 1.25rem 0 rgba(77, 91, 237, 0.1019607843);
  transform: translate3d(0, -2px, 0);
}

.hover-shadow-sm:hover {
  -webkit-box-shadow: 0 0.2rem 1.25rem 0 rgba(77, 91, 237, 0.1019607843);
  box-shadow: 0 0.2rem 1.25rem 0 rgba(77, 91, 237, 0.1019607843);
}

.cursor-pointer {
  cursor: pointer;
}

/*------------------------------------
  Web Space
------------------------------------*/
.start-1 {
  left: 1rem;
}

.end-0 {
  right: 0;
}

.end-1 {
  right: 1rem;
}

.top-1 {
  top: 1rem;
}

.bottom-0 {
  bottom: 0;
}

.bottom-1 {
  bottom: 1rem;
}

.w-150 {
  width: 150%;
}

/*------------------------------------
  Z index
------------------------------------*/
.z-index-n100 {
  z-index: -100;
}

.z-index-n10 {
  z-index: -10;
}

.z-index-n5 {
  z-index: -5;
}

.z-index-n4 {
  z-index: -4;
}

.z-index-n3 {
  z-index: -3;
}

.z-index-n2 {
  z-index: -2;
}

.z-index-n1 {
  z-index: -1;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.z-index-4 {
  z-index: 4;
}

.z-index-5 {
  z-index: 5;
}

.z-index-10 {
  z-index: 10;
}

.z-index-20 {
  z-index: 20;
}

.z-index-50 {
  z-index: 50;
}

.z-index-100 {
  z-index: 100;
}

.lg-backdrop {
  z-index: 1100;
}

.lg-outer {
  z-index: 1200;
}

/*------------------------------------
    Opacity
------------------------------------*/
.opacity-0 {
  opacity: 0;
}

.opacity-10 {
  opacity: 0.1 !important;
}

.opacity-20 {
  opacity: 0.2 !important;
}

.opacity-30 {
  opacity: 0.3 !important;
}

.opacity-40 {
  opacity: 0.4 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-60 {
  opacity: 0.6 !important;
}

.opacity-70 {
  opacity: 0.7 !important;
}

.opacity-80 {
  opacity: 0.8 !important;
}

.opacity-90 {
  opacity: 0.9 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  border-radius: inherit;
}

/*------------------------------------
  Waves
------------------------------------*/
.waves-top-center {
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  bottom: auto;
}

.waves-bottom-center {
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  top: auto;
}

.waves-top-start {
  position: absolute;
  top: -1px;
  left: 0;
}

.waves-top-end {
  position: absolute;
  top: -1px;
  right: 0;
}

.waves-bottom-start {
  position: absolute;
  bottom: -1px;
  left: 0;
}

.waves-bottom-end {
  position: absolute;
  bottom: -1px;
  right: 0;
}

.translate-top-1 {
  transform: translate(0, -1rem);
}

.translate-bottom-1 {
  transform: translate(0, 1rem);
}

.translate-top-2 {
  transform: translate(0, -2rem);
}

.translate-bottom-2 {
  transform: translate(0, 2rem);
}

.translate-top-3 {
  transform: translate(0, -3rem);
}

.translate-bottom-3 {
  transform: translate(0, 3rem);
}

.translate-top-4 {
  transform: translate(0, -4rem);
}

.translate-bottom-4 {
  transform: translate(0, 4rem);
}

.translate-top-5 {
  transform: translate(0, -5rem);
}

.translate-bottom-5 {
  transform: translate(0, 5rem);
}

@keyframes animateWave {
  0% {
    transform: scale(1, 0);
    -webkit-transform: scale(1, 0);
  }
  100% {
    transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
  }
}
.waves-animate > svg {
  display: block;
  transform-origin: bottom;
  animation: animateWave 3s cubic-bezier(0.23, 1, 0.32, 1) forwards;
  -webkit-animation: animateWave 3s cubic-bezier(0.23, 1, 0.32, 1) forwards;
}

/*------------------------------------
	Responsive maps
------------------------------------*/
.responsive-maps {
  position: relative;
  padding-bottom: 75% !important;
  overflow: hidden;
}

.responsive-maps iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

/*------------------------------------
  Social
------------------------------------*/
.text-twitter {
  color: #1da1f2 !important;
}

.text-facebook {
  color: #3b5998 !important;
}

.text-instagram {
  color: #3f729b !important;
}

.text-linkedin {
  color: #3b5998 !important;
}

.text-youtube {
  color: #ed302f !important;
}

.hover-social a:hover .facebook {
  color: #3b5998 !important;
}

.hover-social a:hover .twitter {
  color: #1da1f2 !important;
}

.hover-social a:hover .instagram {
  color: #3f729b !important;
}

.hover-social a:hover .linkedin {
  color: #3b5998 !important;
}

.hover-social a:hover .youtube {
  color: #ed302f !important;
}

/*------------------------------------
  Insert Your Custom SCSS
------------------------------------*/